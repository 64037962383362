import { forwardRef } from 'react';
import { Sublinks } from '../types';

import { HeaderPopUp } from './HeaderPopUp';

interface HeaderSubmenu {
  visible: boolean;
  subLinks: Sublinks[];
}

export const HeaderSubmenu = forwardRef<HTMLDivElement, HeaderSubmenu>(
  ({ subLinks, visible }: HeaderSubmenu, ref) => {
    const sublinks = subLinks.map(({ label, to }, index) => {
      if (label && to) {
        return (
          <a key={label + to + index} href={to}>
            <h3 className="header__mobile-link text-text-400">{label}</h3>
          </a>
        );
      }

      return <hr className="header-popup__separator" key={to + 'separator' + index} />;
    });

    if (!visible) {
      return null;
    }

    return (
      <>
        <HeaderPopUp components={subLinks} ref={ref} />
        <ul className="header__mobile-links">{sublinks}</ul>
      </>
    );
  },
);

HeaderSubmenu.displayName = 'HeaderSubmenu';

import { faMoneyBill1Wave } from '@fortawesome/pro-solid-svg-icons';
import cx from 'classnames';
import { IconBox, Link } from '~/common/components';
import { routes } from '~/constants';
import { useCurrentUser } from '../../../../Auth';
import { useNavigation } from '../../../context';

type Props = {
  onClick?: () => void;
};

export const CreditBalanceMenu = ({ onClick }: Props) => {
  const user = useCurrentUser();
  const { isFromHeader } = useNavigation();

  /**
   * Customers can see credits balance if `seeCreditBalance` is `true`
   * but they can't manage it if `manageCredits` is `false`
   */
  if (!user?.manageCredits && !user?.seeCreditBalance) {
    return null;
  }

  const iconClassName = cx('my-profile-menu-icon h-2 w-2', {
    'my-profile-menu-icon--user-menu': isFromHeader,
  });

  return (
    <div
      className={cx('my-profile-menu__item', 'my-profile-menu__item--column bg-white', {
        'my-profile-menu__item--with-borders': isFromHeader,
      })}
    >
      <div className="menu-item-label">
        {user?.manageCredits ? (
          <Link.Icon
            icon={faMoneyBill1Wave}
            to={routes.profile.billing.credits}
            className={iconClassName}
          />
        ) : (
          <IconBox className={iconClassName} icon={faMoneyBill1Wave} />
        )}

        <span className="my-profile-menu-name">Payment details</span>
      </div>

      <div className="my-profile-menu__additional">
        <div className="my-profile-menu__available">
          <span className="available-balance">{`${user?.currencySymbol} ${user?.balance} `}</span>
          <span className="available-label">available</span>
        </div>
        {user?.manageCredits && (
          <Link.Base
            className="button-text--blue my-profile-menu__credit-link"
            to={routes.profile.billing.credits}
            onClick={onClick}
          >
            Get more credits
          </Link.Base>
        )}
      </div>
    </div>
  );
};

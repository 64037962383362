import { useCurrentUser } from '../Auth';

// This is ugly copypasta from selector
export const useUserRole = () => {
  const user = useCurrentUser();

  if (user?.isStaff) {
    return 'staff';
  }

  if (user?.customerRole !== 'owner') {
    return 'manager';
  }

  if (user?.subscription && user?.hasDailyCapacity) {
    return 'subscription';
  }

  return 'regular';
};

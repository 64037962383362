import { z } from 'zod';
import { countrySchema, customerTypeSchema } from '~/root/domain';

const suspendedReasonSchema = z.object({
  description: z.string(),
  key: z.enum(['failedPaymentCard', 'failedOrderPayment', 'insufficientCredits', 'other']),
  comment: z.string().nullable(),
});

const phoneSchema = z
  .string()
  .nullable()
  .transform((value) => (value ? value.replace(/[^\d]/, '') : value));
const dialCodeSchema = z.coerce.number().nullable();

export const customerSettingsSchema = z.object({
  settings: z.object({
    sharing_order_seen: z.boolean().nullish(),
    style_templates_seen: z.boolean().nullish(),
  }),
});

export type CustomerSettings = z.infer<typeof customerSettingsSchema>['settings'];

export const customerSchema = z
  .object({
    address: z.string().nullable(),
    balance: z.number(),
    cardDigits: z.string().nullable(),
    city: z.string().nullable(),
    company: z.string().nullable(),
    country: countrySchema.nullable(),
    currency: z.string().nullable(),
    currencySymbol: z.string().nullable(),
    customerAccountId: z.number(), // null when user is staff
    customerRole: z.string(), // 'owner'
    customerType: customerTypeSchema, // null when user is staff
    dashboardUrl: z.string().nullable(), // TODO is it nullable?
    dialCode: dialCodeSchema,
    email: z.string(),
    hasCard: z.boolean(),
    hasCredits: z.boolean(),
    hasDailyCapacity: z.boolean(),
    id: z.number(),
    isContract: z.boolean(),
    isStaff: z.boolean(),
    manageCredits: z.boolean(),
    name: z.string(),
    needsQuestions: z.boolean(), // null when user is staff
    notifications: z
      .object({
        phone: phoneSchema,
        dialCode: dialCodeSchema,
      })
      .nullable(),
    orderId: z.number().nullable(), // null when user doesn't have any submitted orders
    phone: phoneSchema,
    purchasedPackages: z.boolean(),
    seeCreditBalance: z.boolean(),
    state: z.string().nullable(),
    subscription: z.boolean(),
    subscriptionType: z.string().nullable(),
    /**
     * Includes names of products in customer's subscription
     * Initially added to disable insufficient funds in order form for users with "Pro" retainer plan
     * Can be removed if CA BE gonna be normalized
     */
    subscriptionProducts: z.array(z.string()).nullable(),
    supportsCustomFields: z.boolean(),
    supportsConfidentialOrder: z.boolean(),
    suspended: z.boolean(),
    suspendedReason: suspendedReasonSchema.nullable(),
    // TODO BE doesn't send token in the user field for oauth login response
    // we're not relying on this token too much, so let's make it optional
    token: z.string().optional(),
    zip: z.string().nullable(),
  })
  .merge(customerSettingsSchema);

export type CustomerUser = z.infer<typeof customerSchema>;

export const staffToCustomerSchemaDiff = {
  customerType: z.null(),
  customerRole: z.string().nullable(),
  needsQuestions: z.boolean().nullable(),
  orderId: z.null(),
  customerAccountId: z.null(),
  isStaff: z.literal(true),
};

export const staffSchema = customerSchema.extend(staffToCustomerSchemaDiff);

export type StaffUser = z.infer<typeof staffSchema>;

export const userSchema = customerSchema.or(staffSchema);

export type CustomerOrStaff = z.infer<typeof userSchema>;

export type User = z.infer<typeof userSchema>;

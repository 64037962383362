import { faCog } from '@fortawesome/pro-duotone-svg-icons';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { faBars, faTimes, faUser } from '@fortawesome/pro-solid-svg-icons';
import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Link, useModalContext, Button, IconBox as NewIconBox } from '~/common/components';
import { useDevice, DESKTOP } from '~/common/kits/device';
import { IconBox } from '~/components';
import { LoginModal } from '~/components/Modal/Modals';
import { routes } from '~/constants';
import { PUBLIC_PAGES_URL, SERVER_URL, ENV } from '~/env';
import { useLockBodyScroll } from '~/hooks';
import { useInit } from '~/root/Auth';
import { logoutRequest } from '~/root/Auth/utils';
import { BCorpLogo } from './components/BCorpLogo';
import DrawerSection from './components/Drawer-section';
import { HeaderLinks } from './components/HeaderLinks';
import { HeaderSocial } from './components/HeaderSocial';
import PopupSection from './components/Popup-section';
import { TFLogo, TFLogoDark } from './images';

import './Header.scss';

const themes = {
  primary: {
    color: 'primary',
    btnColor: 'grey-outlined',
    logo: TFLogoDark,
    separator: 'bg-white opacity-[0.15]',
    hover: 'hover:text-primary-200',
    triangle: 'text-white',
  } as const,
  secondary: {
    color: 'secondary',
    btnColor: 'primary-outlined',
    logo: TFLogo,
    separator: 'bg-other-400 opacity-100',
    hover: 'hover:text-text-600',
    triangle: 'text-primary-300',
  } as const,
};

const HEADER_HEIGHT = 64;

/**
 * TODO when refactoring/redesigning this, check out Navigation
 * There are uses of currentUser hook that should be changed to asserted variant
 * It's like that rn because login button has onClose: toggleMenu
 * and it'll trigger Navigation even when clicking outside
 *
 * Also there is a bug - tablet/mobile user menu for unauthorised users is accessible
 * that needs to be discussed while redesigning
 */
export const Header = () => {
  const device = useDevice();
  const location = useLocation();
  const { modalOpener } = useModalContext();

  const { data: init } = useInit();

  const triggerRef = useRef<HTMLLIElement>(null);

  const [menus, setMenus] = useState({
    isMenuOpen: false,
    isUserMenuOpen: false,
  });
  const [burgerOpened, setBurgerOpened] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [theme, setTheme] = useState<typeof themes.primary | typeof themes.secondary>(
    themes.primary,
  );

  useEffect(() => {
    if (burgerOpened) {
      setMenus((prev) => ({ ...prev, isUserMenuOpen: false }));
    }
  }, [burgerOpened]);

  useEffect(() => {
    if (menus.isUserMenuOpen) {
      setBurgerOpened(false);
    }
  }, [menus.isUserMenuOpen]);

  useEffect(() => {
    const watchScroll = () => {
      if (!scrolled && window.pageYOffset >= HEADER_HEIGHT) {
        setScrolled(true);
        setTheme(themes.secondary);
      } else if (window.pageYOffset < HEADER_HEIGHT) {
        setScrolled(false);
        setTheme(themes.primary);
      }
    };

    window.addEventListener('scroll', watchScroll);

    return () => {
      window.removeEventListener('scroll', watchScroll);
    };
  }, [scrolled]);

  useLockBodyScroll(burgerOpened);

  const menuOpacity = burgerOpened
    ? 'opacity-100 pointer-events-auto transition-opacity duration-300 ease-in'
    : 'opacity-0 pointer-events-none';

  const toggleBurgerMenu = () => setBurgerOpened(!burgerOpened);

  const onSignupOrLogin = ([routes.login, routes.signup] as string[]).includes(location.pathname);

  const closeMenu = () => setMenus((prev) => ({ ...prev, isUserMenuOpen: false }));

  const toggleMenu = () => setMenus((prev) => ({ ...prev, isUserMenuOpen: !prev.isUserMenuOpen }));

  const handleLogout = () => {
    logoutRequest();
    closeMenu();
  };

  return (
    <header
      className={`header header-${theme.color} sticky top-0 flex justify-between w-full z-20 items-center transition-colors duration-300 ease-in`}
    >
      <div className="header__nav flex items-center">
        <a href={PUBLIC_PAGES_URL} className="mr-2">
          <img width="112" src={theme.logo} alt="Logo" />
        </a>
        <hr className={cx('h-3 w-[1px] rounded-[100px] mr-2', theme.separator)} />
        <div className="header__corporation relative">
          <BCorpLogo
            className={cx(
              'w-[21px]',
              theme.color === 'primary' ? 'text-text-100' : 'text-[#929bad]',
            )}
          />

          <div className="header__hint absolute rounded-md bg-text-600 transition-opacity duration-300 ease-in">
            <div className="header__hint-triangle absolute left-1/2 bg-text-600" />
            <p className="max-w-[264px] py-1 px-2 text-8 font-normal leading-12 text-text-100">
              In October 2018, 24Slides became a Certified B Corporation which means we meet high
              standards of social and environmental impact, public transparency, and legal
              accountability to balance profit and purpose.
            </p>
          </div>
        </div>

        {!ENV.PRODUCTION && (
          <preview-switcher
            placement="bottom-start"
            development={ENV.DEVELOPMENT}
            server={SERVER_URL}
          >
            <div className="flex items-center justify-center h-5 w-5 ml-2">
              <Button.Icon
                icon={faCog}
                className="!text-text-100 opacity-0 hover:opacity-100 transition-brand"
              />
            </div>
          </preview-switcher>
        )}
      </div>

      <div className="flex">
        <Button.Base
          className="header__burger-menu box-32 cursor-pointer text-12"
          onClick={toggleBurgerMenu}
        >
          <IconBox icon={burgerOpened ? faTimes : faBars} />
        </Button.Base>

        {!onSignupOrLogin && (
          <Button.Base className="ml-2 header__mobile-account" onClick={toggleMenu}>
            <IconBox className="text-11" size={32} icon={faUser} />
          </Button.Base>
        )}

        {menus.isUserMenuOpen && (
          <Button.Base className="ml-2 header__mobile-trigger" onClick={closeMenu}>
            <IconBox size={32} className="text-11" icon={faTimes} />
          </Button.Base>
        )}
      </div>

      <ul className={cx(menuOpacity, 'header__navigation z-10')}>
        <HeaderLinks hover={theme.hover} />

        <li>
          <Link.Button to={routes.order} color="secondary" className="smo:w-full">
            Order Now
          </Link.Button>
        </li>

        {!onSignupOrLogin && (
          <li ref={triggerRef} className="smo:mt-2 smo:w-full md:ml-2">
            <Button
              onClick={init?.user ? toggleMenu : modalOpener(LoginModal, { onClose: toggleMenu })}
              color={theme.color === 'secondary' ? 'primary-outlined' : 'white-outlined'}
            >
              {init?.user ? (
                <>
                  <span>Account</span>
                  <NewIconBox
                    icon={faChevronDown}
                    className={cx('transition-all text-5', menus.isUserMenuOpen && 'rotate-180')}
                  />
                </>
              ) : (
                'Login'
              )}
            </Button>
          </li>
        )}

        <h3 className="header__social-heading text-11 font-medium leading-14 text-text-600">
          Follow Us
        </h3>

        <li className="header__socials w-fit">
          <HeaderSocial />
        </li>
      </ul>

      {device === DESKTOP ? (
        <PopupSection
          triggerRef={triggerRef}
          isUserMenuOpen={menus.isUserMenuOpen}
          closePopup={closeMenu}
        />
      ) : (
        <DrawerSection
          triggerRef={triggerRef}
          isUserMenuOpen={menus.isUserMenuOpen}
          isDrawerFixed={scrolled}
          closeDrawer={closeMenu}
          logout={handleLogout}
        />
      )}
    </header>
  );
};

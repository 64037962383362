import { useLayoutEffect } from 'react';

export const useLockBodyScroll = (state) => {
  useLayoutEffect(() => {
    const originalStyle = document.body.style.overflow;

    if (!state) {
      return;
    }

    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, [state]);
};

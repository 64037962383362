import { useForm, validator } from 'formoid';
import { FormEvent } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  ModalCore,
  ModalHeader,
  PasswordField,
  PasswordTooltipContent,
  SmallPagePaper,
  Tooltip,
  useModalProps,
  Button,
  Link,
} from '~/common/components';
import { customValidator, propagateBackendValidationErrors } from '~/common/utils';
import { routes } from '~/constants';
import { useResetPassword } from './hooks';
import LockIcon from './lock-icon.svg';

type RouteParams = {
  token: string;
  email: string;
};

const initialValues = {
  password: '',
  confirmPassword: '',
};

export const SetNewPassword = () => {
  const { params } = useRouteMatch<RouteParams>();

  const { mutateAsync } = useResetPassword();

  const modalProps = useModalProps();

  const { fieldProps, handleSubmit, setErrors, isSubmitting, errors } = useForm({
    initialValues,
    validationStrategy: 'onBlur',
    validators: ({ password }) => ({
      password: customValidator.password(),
      confirmPassword: validator.sequence(
        validator.fromPredicate(
          (confirmPassword) => confirmPassword === password,
          'Passwords should match',
        ),
        customValidator.password(),
      ),
    }),
  });

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit((values) => {
      return mutateAsync({ token: params.token, email: params.email, ...values })
        .then(modalProps.open)
        .catch((error) => {
          propagateBackendValidationErrors({ error, setErrors, formErrors: errors });
        });
    });
  };

  return (
    <>
      <SmallPagePaper>
        <h2 className="font-brand-h7b mb-1">Reset password</h2>

        <form onSubmit={submit} className="space-y-3">
          <Tooltip content={<PasswordTooltipContent />} placement="right">
            <PasswordField {...fieldProps('password')} title="Password" />
          </Tooltip>

          <PasswordField {...fieldProps('confirmPassword')} title="Confirm Password" />

          <Button
            className="w-full"
            size="m"
            color="secondary"
            loading={isSubmitting}
            type="submit"
          >
            Reset password
          </Button>
        </form>
      </SmallPagePaper>

      <ModalCore opened={modalProps.opened} onClose={modalProps.close}>
        <ModalHeader onClose={modalProps.close} />

        <div className="flex flex-col items-center w-[440px] smo:w-full gap-3 pt-4 pb-4 smo:px-4">
          <img className="w-8 h-auto" src={LockIcon} alt="lock icon" />
          <p className="font-brand-t3m text-text-500 smo:text-center">
            Password has been successfully changed
          </p>
          <Link size="m" to={routes.login}>
            Login
          </Link>
        </div>
      </ModalCore>
    </>
  );
};

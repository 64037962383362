import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { faFileCheck } from '@fortawesome/pro-solid-svg-icons';
import { uploadPhotoIcon } from '~/assets/images/icons';
import { cx } from '~/common/utils';
import { IconBox } from '../IconContainers';
import { FileUpload, FileUploadProps, NonEmptyItemRendererProps } from './FileUpload';

const nonEmptyRenderer = ({ loading, imagePreviewUrl, clear }: NonEmptyItemRendererProps) => {
  return (
    <>
      {loading ? (
        <IconBox
          size="l"
          icon={loading ? faSpinnerThird : faFileCheck}
          className={cx('mx-[12px] z-10 text-text-100', loading && 'animate-spin')}
        />
      ) : (
        <div
          onClick={clear}
          className="h-full w-full flex flex-col justify-center items-center opacity-0 transition-brand z-10 group-hover:bg-black group-hover:bg-opacity-40 group-hover:opacity-100"
        >
          <IconBox icon={faTrashCan} size="m" className="mx-[12px] text-text-100" />
          <p className="text-text-100 font-brand-l3 ">Remove cover</p>
        </div>
      )}
      {imagePreviewUrl && (
        <img
          src={imagePreviewUrl}
          className={cx(
            'absolute w-full h-full object-cover transition-brand',
            loading && 'brightness-50',
          )}
        />
      )}
    </>
  );
};

const placeholderRenderer = () => {
  return (
    <div className="flex flex-col items-center gap-[4px]">
      <img src={uploadPhotoIcon} width={32} height={32} />
      <p className="font-brand-b2 text-text-300">Choose a cover</p>
      <p className="font-brand-sm text-text-300">Max. size is 2 MB and 4096x2644px </p>
    </div>
  );
};

const acceptedMimeTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/avif', 'image/webp'];

export const PhotoUploadField = (
  props: Omit<FileUploadProps, 'itemRenderer' | 'placeholderRenderer'>,
) => {
  return (
    <FileUpload
      {...props}
      itemRenderer={nonEmptyRenderer}
      placeholderRenderer={placeholderRenderer}
      acceptedMimeTypes={acceptedMimeTypes}
    />
  );
};

import {
  FloatingFocusManager,
  FloatingNode,
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { ReactNode, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { MAX_TAB_INDEX } from '~/common/hooks';
import { cx, getCSSTransitionClassNames } from '~/common/utils';
import { FloatingDiv, WithFloatingTree } from '../Floating';
import css from './ModalCore.module.scss';

type Props = {
  className?: string;
  children: ReactNode;
  opened: boolean;
  onClose: () => void;
  onExiting?: () => void;
};

export const ModalCore = WithFloatingTree(
  ({ className, children, opened, onClose, onExiting }: Props) => {
    const nodeId = useFloatingNodeId();

    const { refs, context } = useFloating({
      nodeId,
      open: opened,
      onOpenChange: (state) => {
        if (!state) {
          onClose?.();
        }
      },
    });
    const overlayRef = useRef(null);

    const { getFloatingProps } = useInteractions([
      useClick(context),
      useRole(context),
      useDismiss(context, {
        // TODO extend this once we know what other extensions our users want to use
        outsidePress: (event) => {
          const target = event.target as Element;
          return !target.shadowRoot && !target.closest('.hot-toast');
        },
      }),
    ]);

    return (
      <FloatingNode id={nodeId}>
        <FloatingPortal>
          <CSSTransition
            classNames={getCSSTransitionClassNames(css)}
            in={opened}
            timeout={300}
            unmountOnExit
            nodeRef={overlayRef}
            onExiting={onExiting}
          >
            <FloatingOverlay ref={overlayRef} lockScroll className={css.backdrop}>
              <FloatingFocusManager context={context} initialFocus={MAX_TAB_INDEX}>
                <FloatingDiv
                  {...getFloatingProps({
                    ref: refs.setFloating,
                    className: cx(css.modal, className),
                  })}
                >
                  {children}
                </FloatingDiv>
              </FloatingFocusManager>
            </FloatingOverlay>
          </CSSTransition>
        </FloatingPortal>
      </FloatingNode>
    );
  },
);

import { ReactNode } from 'react';
import { cx } from '~/common/utils';

interface CardProps {
  children: ReactNode;
  title?: ReactNode;
  className?: string;
}

export const Card = ({ children, title, className }: CardProps) => (
  <div
    className={cx(
      'px-3 md:px-4 py-3 rounded-md bg-white border border-solid border-other-300',
      className,
    )}
  >
    {title && <div className="font-brand-h5 text-text-500 mb-3">{title}</div>}
    {children}
  </div>
);

import { useHistory, useLocation } from 'react-router-dom';
import { Select, Tab, Tabs } from '~/common/components';
import { createContextPair } from '~/common/kits/context';
import { cx, isNonNullable } from '~/common/utils';
import { routes } from '~/constants';
import { useCurrentUserData } from '~/root/Auth';
import { useBillingData } from './hooks';

const tabs = {
  overview: routes.profile.billing.overview,
  payments_history: routes.profile.billing.payments_history,
  credits: routes.profile.billing.credits,
  subscription: routes.profile.billing.subscription,
};

type TabLink = (typeof tabs)[keyof typeof tabs];

interface BillingMenuProps {
  className?: string;
}

export const BillingMenu = ({ className }: BillingMenuProps) => {
  const { subscription } = useBillingData();
  const { isContract, hasDailyCapacity } = useCurrentUserData();
  const { activeTab, setActiveTab } = useBillingMenu();

  const canBuyCredits =
    !(hasDailyCapacity || isContract) &&
    (!subscription?.products.retainer || subscription.products.retainer?.credits === null);

  const options = [
    {
      name: 'Billing overview',
      value: tabs.overview,
    },
    {
      name: 'Payments history',
      value: tabs.payments_history,
    },
    canBuyCredits
      ? {
          name: 'Credit packages',
          value: tabs.credits,
        }
      : null,
    !subscription?.products.retainer
      ? {
          name: 'Subscription',
          value: tabs.subscription,
        }
      : null,
  ].filter(isNonNullable);

  return (
    <>
      <Select
        className={cx('w-full mb-3 md:hidden', className)}
        value={activeTab || null}
        options={options}
        onChange={setActiveTab}
      />
      <Tabs activeTab={activeTab} onChange={setActiveTab} className={cx('smo:hidden', className)}>
        {options.map((option) => (
          <Tab key={option.value} name={option.value}>
            {option.name}
          </Tab>
        ))}
      </Tabs>
    </>
  );
};

const getActiveTab = (pathname: string) => {
  return Object.values(tabs).find((tabLink) => pathname.includes(tabLink));
};

const useContextData = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  return {
    activeTab: getActiveTab(pathname),
    setActiveTab: (tab: TabLink) => {
      history.push(tab);
    },
  };
};

const [useBillingMenu, withBillingMenu] = createContextPair(useContextData);

export { useBillingMenu, withBillingMenu };

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { ReactNode } from 'react';
import { Collapse, IconBox } from '~/common/components';
import { useToggle } from '~/common/hooks';
import { cx } from '~/common/utils';
import { useNavigation } from '../context';

type Props = {
  children: ReactNode;
  title: string;
  icon: IconProp;
};

export const SubNavigation = ({ children, title, icon }: Props) => {
  const { isMenuCollapsed, isFromHeader } = useNavigation();
  const [isCollapsed, toggleCollapsed] = useToggle(false);

  return (
    <>
      {!isMenuCollapsed && (
        <div
          key={title}
          className={cx('my-profile-menu__item cursor-pointer', {
            'my-profile-menu__item--user-menu': isFromHeader,
          })}
          onClick={toggleCollapsed}
        >
          <div className="menu-item-label">
            <IconBox
              className={cx('my-profile-menu-icon h-2 w-2', {
                'my-profile-menu-icon--user-menu': isFromHeader,
              })}
              icon={icon}
            />
            <span className="my-profile-menu-name">{title}</span>
          </div>

          <IconBox icon={faChevronUp} className={cx('ml-auto', isCollapsed && 'rotate-180')} />
        </div>
      )}
      {isMenuCollapsed ? (
        children
      ) : (
        <Collapse in={!isCollapsed} className={cx('w-full pl-3')}>
          {children}
        </Collapse>
      )}
    </>
  );
};

import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { ComponentProps, forwardRef, ReactNode, useState } from 'react';
import { Popover, TriggerProps, Button } from '~/common/components';
import { cx } from '~/common/utils';
import styles from './Menu.module.scss';

export type MenuItemProps = ComponentProps<'button'>;

const MenuItem = forwardRef<HTMLButtonElement, ComponentProps<'button'>>(
  ({ className, children, ...props }, ref) => (
    <Button.Base {...props} ref={ref} className={cx(styles.item, className)}>
      {children}
    </Button.Base>
  ),
);

type MenuProps = Partial<{
  children: ReactNode | ((onClose: () => void) => ReactNode);
  className: string;
  trigger: (props: TriggerProps & { open: boolean }) => JSX.Element;
}>;

export const Menu = ({
  children,
  className,
  trigger = (props) => (
    <Button.Icon size="s" {...props} aria-label="Toggle menu" icon={faEllipsis} />
  ),
}: MenuProps) => {
  const state = useState(false);
  const [, setOpened] = state;

  return (
    <Popover
      externalState={state}
      onClick={children instanceof Function ? undefined : () => setOpened(false)}
      className={cx(styles.container, className)}
      content={(props) => (children instanceof Function ? children(props.onClose) : children)}
      noArrow
      forwardOpened
      placement="bottom-end"
      trigger={trigger}
    />
  );
};

Menu.Item = MenuItem;

import { forwardRef } from 'react';
import { IconBox } from '~/components';
import { Sublinks } from '../types';

interface HeaderPopUpProps {
  components: Sublinks[];
}

export const HeaderPopUp = forwardRef<HTMLDivElement, HeaderPopUpProps>(({ components }, ref) => {
  const CompactBar = components.map(({ label, icon, to }) => {
    if (to && icon) {
      return (
        <a className="mb-1 flex h-5 w-fit items-center" href={to} key={label}>
          <IconBox className="mr-2 text-other-600" icon={icon} />
          <span className="flex items-center text-9 font-normal leading-12 text-text-400">
            {label}
          </span>
        </a>
      );
    }
  });

  return (
    <div className="header-popup_compact" ref={ref}>
      <div className="absolute top-0 left-1/2 z-20 h-1 w-2 rotate-45 bg-white" />
      {CompactBar}
    </div>
  );
});

HeaderPopUp.displayName = 'HeaderPopUp';

import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { Collapse } from '@material-ui/core';
import { useForm, validator } from 'formoid';
import { useMemo } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { CheckboxField, InputField, Switcher, Button, Link } from '~/common/components';
import { useIdParam } from '~/common/hooks';
import { formatMoney, handleErrorMessage, pluralize } from '~/common/utils';
import { routes } from '~/constants';
import { useDiscountCode, withDiscountCode } from '~/root/discount';
import { useBillingData, useCreditPackagesData, usePurchasePackage } from '../hooks';
import { PaymentMethodsBlock } from '../PaymentMethodsBlock';
import { ProductInfo, PurchaseSummaryProps, Summary } from '../PurchaseSummary';
import { getAutoTopupFieldsValidation } from './AutoTopup';

const DEFAULT_AUTO_TOPUP_TRESHOLD = 250;

const usePurchaseCreditsPackagesForm = () => {
  const { defaultPaymentMethod } = useBillingData();

  return useForm({
    initialValues: {
      termsAndConditions: false,
      dataProcessingAgreement: false,
      enableTopUp: false,
      auto_topup_threshold: DEFAULT_AUTO_TOPUP_TRESHOLD,
      paymentMethod: null,
    },
    validators: (values) => ({
      termsAndConditions: validator.fromPredicate<boolean>(
        (value) => value === true,
        'Please accept our terms and contitions to continue with purchase.',
      ),
      dataProcessingAgreement: validator.fromPredicate<boolean>(
        (value) => value === true,
        'Please accept our data processing agreement to continue with purchase.',
      ),
      paymentMethod: validator.fromPredicate(
        () => !!defaultPaymentMethod,
        'Please add payment method.',
      ),
      ...getAutoTopupFieldsValidation(values),
    }),
    validationStrategy: 'onSubmit',
  });
};

export const PurchaseCreditsPackage = withDiscountCode(() => {
  const history = useHistory();
  // TODO: Fix decode error handling, now it breaks the whole page
  const creditPackageId = useIdParam();
  const creditsPackages = useCreditPackagesData();
  const { vatPercent, defaultPaymentMethod } = useBillingData();
  const { couponCode } = useDiscountCode();

  const purchasePackageMutation = usePurchasePackage();

  const creditsPackage = useMemo(
    () => creditsPackages.find((cp) => cp.id === creditPackageId),
    [creditPackageId, creditsPackages],
  );

  const form = usePurchaseCreditsPackagesForm();

  if (!creditsPackage) {
    return <Redirect to={routes.profile.billing.credits} />;
  }

  const vatAmount = (creditsPackage.amount * vatPercent) / 100;

  const returnToCreditsPage = () => history.push(routes.profile.billing.credits);

  const onSubmit = () =>
    form.handleSubmit(({ enableTopUp, auto_topup_threshold }) =>
      purchasePackageMutation.mutateAsync(
        {
          coupon: couponCode || null,
          method: defaultPaymentMethod?.id || null,
          packageId: creditPackageId,
          topUp: enableTopUp,
          topUpCreditsBelow: enableTopUp ? auto_topup_threshold : null,
        },
        {
          onSuccess: returnToCreditsPage,
          onError: handleErrorMessage,
        },
      ),
    );

  return (
    <>
      <Link to={routes.profile.billing.credits} icon={faChevronLeft} className="mb-3 sm:hidden">
        Back to credit packages
      </Link>

      <PurchaseSummary
        product={{
          name: creditsPackage.name,
          type: 'CreditPackage',
          price: (
            <>
              {formatMoney(creditsPackage.amount)}
              <span className="font-brand-b2 ml-[4px]">
                {formatMoney(creditsPackage.credits)} credit{pluralize(creditsPackage.credits)}
              </span>
            </>
          ),
          advantages: creditsPackage.pros,
          bonus: creditsPackage.discount.percentage,
        }}
        summary={[
          { title: 'Package type', value: creditsPackage.name },
          // Assuming that 1 credit = 1$ without credits package
          { title: 'Price', value: formatMoney(creditsPackage.credits) },
          {
            title: 'Credits',
            value: `${formatMoney(creditsPackage.credits)} (credits never expire)`,
          },
          {
            title: 'Discount',
            value: `${formatMoney(creditsPackage.discount.amount)} (${
              creditsPackage.discount.percentage
            }% from each package)`,
          },
          { title: 'VAT', hint: 'Your company country VAT', value: formatMoney(vatAmount) },
        ]}
        amount={creditsPackage.amount}
        form={form}
      />

      <PaymentMethodsBlock className="mt-3" errors={form.fieldProps('paymentMethod').errors} />

      <CheckboxField
        {...form.fieldProps('termsAndConditions')}
        title={
          <>
            I agree to the{' '}
            <Link href={routes.public.documents.terms_and_conditions} newTab>
              Terms and Conditions
            </Link>
          </>
        }
        className="mt-4"
      />
      <CheckboxField
        {...form.fieldProps('dataProcessingAgreement')}
        title={
          <>
            I accept{' '}
            <Link href={routes.public.documents.data_processing_agreement} newTab>
              Data Processing agreement
            </Link>
          </>
        }
        className="mt-1"
      />

      <div className="w-full flex flex-row-reverse gap-x-2 py-2 mt-3">
        <Button
          size="m"
          className="w-[200px] sm:w-full grow-2"
          onClick={onSubmit}
          loading={purchasePackageMutation.isLoading}
        >
          Confirm
        </Button>
        <Button
          color="primary-outlined"
          size="m"
          className="hidden sm:flex grow-0"
          onClick={returnToCreditsPage}
          disabled={purchasePackageMutation.isLoading}
        >
          Back
        </Button>
      </div>
    </>
  );
});

const PurchaseSummary = ({
  product,
  summary,
  amount,
  form,
}: PurchaseSummaryProps & { form: ReturnType<typeof usePurchaseCreditsPackagesForm> }) => {
  return (
    <>
      <div className="flex flex-col lg:flex-row justify-between p-3 md:p-4 bg-white rounded-md">
        <ProductInfo {...product}>
          <Switcher title="Automatically top-up my credits" {...form.fieldProps('enableTopUp')} />
          <Collapse in={form.values.enableTopUp}>
            <InputField
              type="number"
              title="When credit balance falls below"
              placeholder="Enter number of credits"
              className="max-w-[268px]"
              {...form.fieldProps('auto_topup_threshold')}
            />
          </Collapse>
        </ProductInfo>
        <Summary summary={summary} amount={amount} />
      </div>
    </>
  );
};

import {
  faChevronUp,
  faChevronDown,
  faTags,
  faCheckCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { useMutation } from '@tanstack/react-query';
import { useForm, validator } from 'formoid';
import { useRef, useState } from 'react';
import { z } from 'zod';
import { SubscriptionPlanPeriod } from '~/pages/My-profile/components/Billing/domain';
import { subscriptionPeriodToMonthAmount } from '~/pages/My-profile/components/Billing/Subscription/constants';
import { axios } from '~/root';
import { Collapse, IconBox, IconButton, InputField, Button } from '../common/components';
import { useToggle } from '../common/hooks';
import { createContextPair } from '../common/kits/context';
import { cx } from '../common/utils';
import { ProductType } from './domain';

const couponValidationBaseSchema = z.object({
  status: z.enum(['success', 'error']),
  title: z.string().nullable(),
  message: z.string(),
});

// Order Discount Natural (discount like 3 hundred bucks)
const orderDiscountCouponSchema = couponValidationBaseSchema.extend({
  fields: z.object({
    discount: z.number(),
    minOrderAmount: z.number(),
    discountType: z.literal('natural'),
  }),
});

// Order Discount Percent (f.e 69)
const orderPercentageDiscountCouponSchema = couponValidationBaseSchema.extend({
  fields: z.object({
    discount: z.number().min(0).max(100),
    minOrderAmount: z.number(),
    discountType: z.literal('percent'),
  }),
});

// Order Free Slides
const orderFreeSlidesCouponSchema = couponValidationBaseSchema.extend({
  fields: z.object({
    slides: z.number(),
    price: z.number(),
  }),
});

// Order Trial
const orderTrialCouponSchema = couponValidationBaseSchema.extend({
  fields: z.object({
    price: z.number(),
  }),
});

// Pro Plan Discount Percent 100%
const subscriptionCouponSchema = couponValidationBaseSchema.extend({
  fields: z.object({
    discount: z.number().min(0).max(100),
  }),
});

const otherCouponSchema = couponValidationBaseSchema.extend({ fields: z.array(z.any()) });

export const couponValidationSchema = orderPercentageDiscountCouponSchema
  .or(orderDiscountCouponSchema)
  .or(orderFreeSlidesCouponSchema)
  .or(orderTrialCouponSchema)
  .or(otherCouponSchema)
  .or(subscriptionCouponSchema);

export type CouponValidationResult = z.infer<typeof couponValidationSchema>;

export const useCouponValidation = () => {
  return useMutation({
    mutationFn: ({
      couponCode,
      product,
      period,
    }: {
      couponCode: string;
      product: ProductType;
      period: number;
    }) => {
      const data: { product: ProductType; payload?: { plan: 'Pro'; period: number } } = { product };
      if (product === 'AccountSubscription') {
        data.payload = { plan: 'Pro', period };
      }
      return axios
        .post(`/v1/coupons/${couponCode}/validate`, data)
        .then((res) => couponValidationSchema.parse(res.data))
        .catch((error) => {
          return Promise.reject(
            typeof error?.response?.data?.message === 'string'
              ? error.response.data.message
              : 'Server error when validating coupon',
          );
        });
    },
  });
};

const useContextData = () => {
  const form = useForm({
    initialValues: {
      couponCode: '',
    },
    validators: () => ({
      couponCode: validator.minLength(1, 'Please specify a coupon'),
    }),
    validationStrategy: 'onBlur',
  });

  const [coupon, setCoupon] = useState<CouponValidationResult & { code: string }>();

  const { mutateAsync, isLoading: isCouponValidating } = useCouponValidation();

  const validateCouponCode = (product: ProductType, period: number) =>
    form.handleSubmit(({ couponCode }) =>
      mutateAsync({ couponCode, product, period })
        .then((coupon) => {
          if (coupon.status === 'error') {
            form.setErrors('couponCode', [coupon.message]);

            return;
          }

          setCoupon({ ...coupon, code: couponCode });
          form.handleReset();
        })
        .catch((error) => {
          form.setErrors('couponCode', [error]);
        }),
    );

  const removeCoupon = () => {
    setCoupon(undefined);
    form.handleReset();
  };

  return {
    form,
    discount: coupon && 'discount' in coupon.fields ? coupon.fields.discount : undefined,
    /** Valid discount code */
    couponCode: coupon?.code,
    isCouponValidating,
    validateCouponCode,
    removeCoupon,
  };
};

const [useDiscountCode, withDiscountCode] = createContextPair(useContextData);

export { useDiscountCode, withDiscountCode };

interface DiscountProps {
  title?: string;
  className?: string;
  product: ProductType;
  period: SubscriptionPlanPeriod;
}

/**
 * Use inside components, wrapped in with ```withDiscountCode ```
 * ```tsx
 *  <Discount
 *    title="I want apply a promo code"
 *    className="mb-1 pt-1"
 *  />
 * ```
 */
export const Discount = ({
  title = 'I have a promo code',
  className,
  product,
  period,
}: DiscountProps) => {
  const [isCollapsed, toggleCollapsed] = useToggle(true);

  // TODO: Add Applied coupon view with "reset" button or smth
  return (
    <div className={cx('rounded-md border-solid border border-other-300', className)}>
      <div
        className="flex p-[12px] gap-x-1 items-center md:min-w-[370px]"
        onClick={toggleCollapsed}
      >
        <IconBox className="text-other-600" size="m" icon={faTags} />
        <p className="font-brand-b1 w-full">{title}</p>
        <IconButton
          icon={isCollapsed ? faChevronDown : faChevronUp}
          size="m"
          className="text-text-400"
        />
      </div>
      <Collapse in={!isCollapsed}>
        <CouponCode product={product} period={period} />
      </Collapse>
    </div>
  );
};

const CouponCode = ({ product, period }: Pick<DiscountProps, 'product' | 'period'>) => {
  const inputRef = useRef<HTMLDivElement>(null);
  const { form, couponCode, isCouponValidating, validateCouponCode, removeCoupon } =
    useDiscountCode();

  const applyCoupon = () => {
    validateCouponCode(product, subscriptionPeriodToMonthAmount[period]);
  };

  const handleKeyUp: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      applyCoupon();
    }
  };

  const handleRemoveCouponClick = () => {
    removeCoupon();
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  return (
    <div className="flex flex-col md:flex-row gap-2 px-3 pb-3 pt-[4px]">
      {couponCode ? (
        <div className="flex items-center justify-between w-full h-6 px-[12px] bg-other-50 border border-other-500 border-solid rounded">
          <div className="inline-flex items-center gap-1">
            <IconBox icon={faCheckCircle} color="success" className="text-success-300" />
            <span className="font-brand-c2">
              {couponCode} <span className="font-brand-b2">applied</span>
            </span>
          </div>
          <Button color="text" onClick={handleRemoveCouponClick}>
            Remove
          </Button>
        </div>
      ) : (
        <>
          <InputField
            {...form.fieldProps('couponCode')}
            ref={inputRef}
            onKeyUp={handleKeyUp}
            type="text"
            placeholder="Enter coupon code"
          />
          <Button size="m" onClick={applyCoupon} loading={isCouponValidating || form.isSubmitting}>
            Apply
          </Button>
        </>
      )}
    </div>
  );
};

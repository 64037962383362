import { createContext } from 'react';

export const billingFields = [
  'billingEmail',
  'companyName',
  'vat',
  'cardBillingCity',
  'cardBillingCountry',
  'cardBillingState',
  'cardBillingStreet',
  'cardBillingZip',
  'invoiceCity',
  'invoiceCountry',
  'invoicePoNumber',
  'invoiceState',
  'invoiceStreet',
  'invoiceZip',
];

// It is used for provide `FormHandler` data and methods for custom order forms' components
export const FormStorageContext = createContext();

// Custom order forms URLs and names
export const CustomFormRoutes = {
  '/order-now': 'stepped',
  '/order-ads': 'ads',
  '/quote': 'quote',
  '/try-us-out': 'try-us-out',
  '/10-for-49': '10-for-49',
  '/order-quote': 'quote',
  '/novonordisk-order': 'novonordisk',
  '/fti-order': 'fti',
  '/llamasoft-order': 'llamasoft',
  '/sika-order': 'sika',
  '/westpharma-order': 'westpharma',
  '/ptc-order': 'ptc',
  '/mackenzie-order': 'mackenzie',
  '/swissre-order': 'swissre',
  '/optum-order': 'optum',
  '/bat-order': 'bat',
  '/msd-order': 'msd',
  '/nielseniq-order': 'nielseniq',
  '/lonza-order': 'lonza',
  '/devoteam-order': 'devoteam',
  '/novozymes-order': 'novozymes',
  '/kkwindsolutions-order': 'kkwindsolutions',
  '/pwc-order': 'pwc',
  '/iss-order': 'iss',
  '/materialplus-order': 'materialplus',
  '/cvc-order': 'cvc',
  '/cbre-order': 'cbre',
  '/manus-order': 'manus',
  '/novonesis-order': 'novonesis',
} as const;

export type FormNames = (typeof CustomFormRoutes)[keyof typeof CustomFormRoutes];

/**
 * For compatibility
 */
export const ComponentNames = {
  TREATMENTS: 'treatments',
  STYLES: 'styles',
  ADDONS: 'addons',
  SLIDES_NUMBER: 'slidesNumber',
  DELIVERY_DATES: 'deliveryDates',
  UPLOAD_FILES: 'uploadFiles',
  BRIEF: 'brief',
  PAYMENT_DETAILS: 'paymentDetails',
  COUPON: 'coupon',
  SIGNUP: 'signup',
  SUBMIT: 'submit',
  EXAMPLES: 'examples',
  OUR_RECOMMENDATION: 'ourRecommendation',
  CUSTOM_FIELDS: 'customFields',
};

export const ComponentMap = {
  [ComponentNames.TREATMENTS]: 'Treatments',
  [ComponentNames.STYLES]: 'Styles',
  [ComponentNames.ADDONS]: 'ProofReading',
  [ComponentNames.SLIDES_NUMBER]: 'SlidesNumber',
  [ComponentNames.DELIVERY_DATES]: 'DeliveryDates',
  [ComponentNames.UPLOAD_FILES]: 'UploadFiles',
  [ComponentNames.BRIEF]: 'Brief',
  [ComponentNames.PAYMENT_DETAILS]: 'PaymentDetails',
  [ComponentNames.SIGNUP]: 'Signup',
  [ComponentNames.SUBMIT]: 'Submit',
  [ComponentNames.EXAMPLES]: 'Examples',
  [ComponentNames.COUPON]: 'Coupon',
  [ComponentNames.OUR_RECOMMENDATION]: 'OurRecommendation',
  [ComponentNames.CUSTOM_FIELDS]: 'CustomFields',
};

// Errors hints timeout for Step-toolbar component
export const ERROR_HINT_TIMEOUT = 3250;

// time for restoring order data from local storage
export const ORDER_FORM_LIFETIME_MINUTES = 1440;

export const mimeTypesFile = `
  image/*,
  video/mp4,
  image/psd,
  image/vnd.adobe.photoshop,
  text/plain,
  .ai,
  .cdr,
  .doc,
  .docm,
  .docx,
  'eps',
  .eps,
  'otf',
  .otf,
  'pdf',
  .potm,
  .ppt,
  .pptx,
  .psd,
  'ttf',
  .ttf,
  'txt',
  'woff',
  .woff,
  'woff2',
  .woff2,
  .xls,
  .xlsx,
  application/vnd.ms-excel,
  application/msexcel,
  application/x-msexcel,
  application/x-ms-excel,
  application/x-excel,
  application/x-dos_ms_excel,
  application/xls,
  application/vnd.ms-powerpoint,
  application/vnd.ms-powerpoint [official],
  application/vnd.ms-powerpoint.template.macroEnabled.12,
  application/mspowerpoint,
  application/ms-powerpoint,
  application/mspowerpnt,
  application/vnd-mspowerpoint
  application/powerpoint,
  application/x-powerpoint,
  application/x-m,
  application/vnd.openxmlformats-officedocument.presentationml.presentation,
  application/vnd.openxmlformats-officedocument.presentationml.template,
  application/vnd.openxmlformats-officedocument.presentationml.slideshow,
  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
  application/vnd.openxmlformats-officedocument.spreadsheetml.template,
  application/msword,
  application/pdf,
  application/zip,
  application/x-zip-compressed,
  application/x-rar-compressed,
  application/x-7z-compressed,
  application/vnd.ms-word.document.macroenabled.12,
  application/vnd.ms-word.template.macroenabled.12,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document,
  application/x-iwork-keynote-sffkey,
  application/x-iwork-pages-sffpages,
  application/x-iwork-numbers-sffnumbers,
  application/x-photoshop,
  application/photoshop,
  application/psd,
  application/vnd.apple.keynote,
  application/vnd.apple.pages,
  application/vnd.apple.numbers,
  application/octet-stream,
  multipart/x-zip,
`;

export const mimeTypesImage = `
  image/jpeg,
  image/png,
`;

export const MAX_SIZE_FILE = 925 * 1024 * 1024;
export const MAX_SIZE_IMAGE = 2 * 1024 * 1024;

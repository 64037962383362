import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { ReactNode } from 'react';
import { Button, IconBox, ModalContentProps, Tooltip } from '~/common/components';
import { cx } from '~/common/utils';
import { ButtonProps } from '../Interactives/types';

type ModalHeaderProps = {
  title?: string;
  subtitle?: ReactNode;
  className?: string;
  onClose?: () => void;
};

export const ModalCloseButton = ({
  onClose,
  className,
}: Pick<ModalHeaderProps, 'onClose' | 'className'>) => (
  <Button.Icon
    icon={faTimes}
    className={cx(
      '!absolute top-0 right-0 !h-7 !w-7 def:!text-text-200 cursor-pointer focus:outline-none z-10',
      className,
    )}
    onClick={onClose}
  />
);

export const ModalHeader = ({ title, subtitle, className, onClose }: ModalHeaderProps) => (
  <div className={cx(className, 'relative min-w-0 def:mb-3')}>
    {title && (
      <div className="inline-flex flex-col pl-3 md:px-4 pt-4 text-text-500">
        <span className="font-brand-h5">{title}</span>
        {typeof subtitle === 'string' ? (
          <span className="inline-block font-brand-b2 text-text-400 mt-1">{subtitle}</span>
        ) : (
          subtitle
        )}
      </div>
    )}
    {onClose && <ModalCloseButton onClose={onClose} />}
  </div>
);

type Props = {
  disabled?: boolean;
  disabledHint?: string;
  loading?: boolean;
  children?: ReactNode;
  className?: string;
  buttonsSize?: ButtonProps['size'];
  negativeAction?: boolean;
  submitText?: string;
  cancelText?: string;
  onClose: () => void;
  onSubmit: () => void;
};

export const ModalShell = ({
  loading = false,
  disabled,
  disabledHint,
  className,
  negativeAction = false,
  submitText = 'Save',
  cancelText = 'Cancel',
  buttonsSize = 'm',
  onClose,
  onSubmit,
  children,
}: Props) => (
  <>
    <div className={cx('def:px-3 def:md:px-4 def:w-[616px]', className)}>{children}</div>
    <div className="flex flex-col-reverse md:flex-row items-center justify-end gap-y-2 gap-x-4 p-3 pb-4 md:p-4 min-w-0">
      <Button color="text" size={buttonsSize} onClick={onClose} disabled={loading}>
        {cancelText}
      </Button>
      <Tooltip content={disabled && disabledHint}>
        <Button
          className={cx(disabled && 'cursor-not-allowed', 'w-full md:w-auto')}
          color={negativeAction ? 'error' : 'primary'}
          size={buttonsSize}
          onClick={onSubmit}
          loading={loading}
          disabled={disabled}
        >
          {submitText}
        </Button>
      </Tooltip>
    </div>
  </>
);

type ConfirmModalShellProps = ModalContentProps & {
  onConfirm: () => void;
  title: string;
  description: string;
  submitText: string;
  loading?: boolean;
};

export const ConfirmModalShell = ({
  title,
  description,
  submitText,
  loading,
  onConfirm,
  onClose,
}: ConfirmModalShellProps) => (
  <>
    <ModalHeader title={title} onClose={onClose} className="mb-1" />
    <ModalShell
      submitText={submitText}
      onClose={onClose}
      onSubmit={onConfirm}
      loading={loading}
      className="py-0 w-[632px]"
    >
      <div className="flex gap-1 mb-1 text-error-300 font-brand-b2">
        <IconBox icon={faExclamationTriangle} className="h-3 w-3" />
        <p>{description}</p>
      </div>
    </ModalShell>
  </>
);

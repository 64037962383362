import ExitToAppIcon from 'mdi-react/ExitToAppIcon';
import { Any, handleEnterPress } from '~/common/utils';
import { logoutRequest } from '../../Auth/utils';

export const LogoutItem = () => (
  <div
    role="button"
    tabIndex={0}
    className="my-profile-menu__item my-profile-menu__item--with-hover my-profile-menu__item--user-menu"
    onClick={logoutRequest}
    // TODO fix this someday
    onKeyUp={handleEnterPress(logoutRequest) as Any}
  >
    <div className="menu-item-label">
      <ExitToAppIcon className="my-profile-menu-icon" color="#ED715C" size={20} />
      <span className="my-profile-menu-name">Logout</span>
    </div>
  </div>
);

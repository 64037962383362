import { RefObject } from 'react';
import { CSSTransition } from 'react-transition-group';

import { useProperClickOutside } from '~/hooks';
import { Navigation } from '~/root';

import './_Popup-section.scss';

interface PopupSectionProps {
  isUserMenuOpen: boolean;
  closePopup: () => void;
  triggerRef: RefObject<HTMLLIElement>;
}

const PopupSection = ({ isUserMenuOpen, closePopup, triggerRef }: PopupSectionProps) => {
  const ref = useProperClickOutside(closePopup, triggerRef);

  return (
    <CSSTransition
      in={isUserMenuOpen}
      classNames="popup-appearance-transition"
      timeout={225}
      mountOnEnter
      unmountOnExit
    >
      <div ref={ref as unknown as RefObject<HTMLDivElement>} className="popup-section">
        {isUserMenuOpen && <Navigation isFromHeader />}
      </div>
    </CSSTransition>
  );
};

export default PopupSection;

import { useEffect, useRef } from 'react';

export function useProperClickOutside(onClickOutside, triggerRef) {
  const elementRef = useRef();

  useEffect(() => {
    const handler = (event) => {
      const isClickedOutside =
        elementRef.current &&
        triggerRef.current &&
        !elementRef.current.contains(event.target) &&
        !triggerRef.current.contains(event.target);

      if (isClickedOutside) {
        onClickOutside();
      }
    };

    window.addEventListener('mousedown', handler);

    return () => window.removeEventListener('mousedown', handler);
  }, [elementRef, onClickOutside, triggerRef]);

  return elementRef;
}

import { EnterpriseBanner } from '~/components/partials/SubscriptionBanner';
import { useCurrentUser } from '~/root/Auth';
import { SubNavigation } from '../components';
import { Credits } from '../components/banners';
import { ProBanner } from '../components/banners/Pro';
import { NavigationLink } from '../components/NavigationLink';
import { NavigationSection } from '../components/NavigationSection';
import { useNavigation } from '../context';

export const ManagerNavigation = () => {
  const { config, isMenuCollapsed, isFromHeader } = useNavigation();
  //TODO change to asserted hook while redesigning header
  const user = useCurrentUser();
  // This is for avoiding empty divider section
  const hideMiddleBanner =
    user?.hasDailyCapacity ||
    (user?.purchasedPackages
      ? !user?.manageCredits && !user?.seeCreditBalance
      : !user?.manageCredits);
  return (
    <>
      {hideMiddleBanner ? (
        <>
          <NavigationSection>
            <NavigationLink {...config.links.myOrders} />
            <NavigationLink {...config.links.myTeam} />
            <NavigationLink {...config.links.billing} />
            <NavigationLink {...config.links.capacityOverview} />
            <SubNavigation {...config.subMenus.settings}>
              <NavigationLink {...config.links.userDetails} />
              <NavigationLink {...config.links.security} />
            </SubNavigation>
            <NavigationLink {...config.links.needHelp} />
          </NavigationSection>
        </>
      ) : (
        <>
          <NavigationSection>
            <NavigationLink {...config.links.myOrders} />
            <NavigationLink {...config.links.myTeam} />
            <NavigationLink {...config.links.billing} />
            <NavigationLink {...config.links.capacityOverview} />
          </NavigationSection>
          <NavigationSection>
            <Credits />
          </NavigationSection>
          <NavigationSection>
            <SubNavigation {...config.subMenus.settings}>
              <NavigationLink {...config.links.userDetails} />
              <NavigationLink {...config.links.security} />
            </SubNavigation>
            <NavigationLink {...config.links.needHelp} />
          </NavigationSection>
        </>
      )}
      {isFromHeader && !user?.subscription && (
        <NavigationSection>
          <ProBanner className="mx-2" />
        </NavigationSection>
      )}
      {!isMenuCollapsed &&
        !isFromHeader &&
        (user?.subscription ? <EnterpriseBanner /> : <ProBanner />)}
    </>
  );
};

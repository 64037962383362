import { faClock } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Button } from '~/common/components';
import './SubscriptionBanner.scss';
import { routes } from '~/constants';

export const EnterpriseBanner = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: 'event',
      eventCategory: 'enterprise',
      eventAction: 'launch-banner',
      eventLabel: 'viewed',
    });
  }, []);

  const handleButtonClick = () => {
    ReactGA.send({
      hitType: 'event',
      eventCategory: 'enterprise',
      eventAction: 'launch-banner',
      eventLabel: 'clicked',
    });

    window.location = `${routes.public.enterprises}?utm_source=customer-area&utm_medium=banner&utm_campaign=popup`;
  };

  return (
    <div className="enterprise subscription-banner subscription-banner__container on-page">
      <div className="bb size-48" data-icon="clock">
        <FontAwesomeIcon icon={faClock} className="info-300" />
      </div>

      <p className="text-center">See how we saved our clients over 100,000 work hours last year</p>

      <Button color="secondary" className="subscription-banner__button" onClick={handleButtonClick}>
        Find out more
      </Button>
    </div>
  );
};

import { PUBLIC_PAGES_URL } from '~/env';

export const PROFILE = '/my-profile';
export const HELP = 'https://help.24slides.com';

export const socials = {
  facebook: 'https://www.facebook.com/24slides',
  instagram: 'https://www.instagram.com/24slides/',
  linkedin: 'https://www.linkedin.com/company/24slides',
  x: 'https://x.com/24slides',
};

export const ORDER_SHARING_HELPCENTER = `${HELP}/en/articles/8778072-share-your-order`;

/**
 * Routing map for CA, use instead of hardcoded strings
 * Each section have it's "index" path that should be used in default redirects to it
 */
export const routes = {
  join: '/join',
  landings: {
    devoteam: '/devoteam',
    kkwind: '/kkwindsolutions',
    lonza: '/lonza',
    merck: '/msd',
    nielsen: '/nielseniq',
    novozymes: '/novozymes',
    optum: '/optum',
    tabacco: '/bat',
  },
  loading: '/loading',
  login: '/login',
  order: '/order-now',
  profile: {
    index: PROFILE,
    billing: {
      index: `${PROFILE}/billing`,
      overview: `${PROFILE}/billing/overview`,
      payments_history: `${PROFILE}/billing/payments-history`,
      credits: `${PROFILE}/billing/credits`,
      credits_purchase: `${PROFILE}/billing/credits/purchase`,
      subscription: `${PROFILE}/billing/subscription`,
      subscription_purchase: `${PROFILE}/billing/subscription/purchase`,
      thank_you: `${PROFILE}/billing/subscription/thank-you`,
    },
    style_templates: `${PROFILE}/style-templates`,
    fields: `${PROFILE}/custom-fields`,
    orders: `${PROFILE}/orders`,
    user_details: `${PROFILE}/user-details`,
    security: `${PROFILE}/security`,
    team: `${PROFILE}/team`,
    capacity_overview: '/reports/capacity-overview',
  },
  public: {
    about: {
      index: `${PUBLIC_PAGES_URL}/about`,
      conduct: `${PUBLIC_PAGES_URL}/about/code-of-conduct`,
      impact: `${PUBLIC_PAGES_URL}/about/impact`,
      purpose: `${PUBLIC_PAGES_URL}/about/purpose`,
      story: `${PUBLIC_PAGES_URL}/about/our-story`,
      team: `${PUBLIC_PAGES_URL}/about/team/indonesia`,
    },
    blog: `${PUBLIC_PAGES_URL}/blog`,
    contact_us: `${PUBLIC_PAGES_URL}/contact-us`,
    dedicated_team: `${PUBLIC_PAGES_URL}/dedicated-teams`,
    documents: {
      cookies_policy: `${PUBLIC_PAGES_URL}/cookies-policy`,
      data_processing_agreement: 'https://go.24slides.com/dpa',
      privacy_policy: `${PUBLIC_PAGES_URL}/privacy-policy`,
      terms_and_conditions: `${PUBLIC_PAGES_URL}/terms-and-conditions`,
    },
    enterprises: `${PUBLIC_PAGES_URL}/enterprises`,
    examples: `${PUBLIC_PAGES_URL}/presentation-examples`,
    master_template: `${PUBLIC_PAGES_URL}/branded-master-template`,
    present_better: `${PUBLIC_PAGES_URL}/presentbetter`,
    pricing: {
      credits: `${PUBLIC_PAGES_URL}/pricing/credit-packages`,
      quote: `${PUBLIC_PAGES_URL}/pricing/quote`,
      slides: `${PUBLIC_PAGES_URL}/pricing/slide-prices`,
    },
    reviews: {
      ratings: `${PUBLIC_PAGES_URL}/reviews/ratings-and-feedback`,
      stories: `${PUBLIC_PAGES_URL}/reviews/customer-stories`,
    },
    security: `${PUBLIC_PAGES_URL}/security`,
    services: `${PUBLIC_PAGES_URL}/presentation-services`,
    templates: `${PUBLIC_PAGES_URL}/templates`,
    treatments: {
      fixup: `${PUBLIC_PAGES_URL}/treatments/fixup`,
      redesign: `${PUBLIC_PAGES_URL}/treatments/redesign`,
    },
  },
  reset_password: '/reset-password',
  signup: '/signup',
  unsubscribe: '/unsubscribe',
  ui_kit: '/ui-kit',
} as const;

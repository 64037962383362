import { faFacebook, faLinkedin, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Link } from '~/common/components';
import { IconBox } from '~/components';
import { socials } from '~/constants';

const socialPaths = [
  { label: 'LinkedIn', to: socials.linkedin, external: true, icon: faLinkedin },
  { label: 'Facebook', to: socials.facebook, external: true, icon: faFacebook },
  { label: 'Instagram', to: socials.instagram, external: true, icon: faInstagram },
  { label: 'Twitter', to: socials.x, external: true, icon: faTwitter },
];

export const HeaderSocial = () => {
  return (
    <>
      {socialPaths.map(({ external, label, icon, to }) => (
        <Link.Base
          key={label}
          to={to}
          newTab={external}
          className="header__social-link text-text-400 transition-colors duration-300 ease-in hover:text-text-500"
        >
          <IconBox className="mr-1 text-11" icon={icon} />
          <span>{label}</span>
        </Link.Base>
      ))}
    </>
  );
};

import { EnterpriseBanner } from '~/components/partials/SubscriptionBanner';
import { SubNavigation } from '../components';
import { NavigationLink } from '../components/NavigationLink';
import { NavigationSection } from '../components/NavigationSection';
import { StyleTemplatesLink } from '../components/StyleTemplatesLink';
import { useNavigation } from '../context';

export const SubscriptionNavigation = () => {
  const { config, isMenuCollapsed, isFromHeader } = useNavigation();

  return (
    <>
      <NavigationSection>
        <NavigationLink {...config.links.myOrders} />
        <NavigationLink {...config.links.myTeam} />
        <NavigationLink {...config.links.billing} />
        <NavigationLink {...config.links.customFields} />
        <StyleTemplatesLink />
        <NavigationLink {...config.links.capacityOverview} />
        <SubNavigation {...config.subMenus.settings}>
          <NavigationLink {...config.links.userDetails} />
          <NavigationLink {...config.links.security} />
        </SubNavigation>
        <NavigationLink {...config.links.needHelp} />
      </NavigationSection>
      {!isMenuCollapsed && !isFromHeader && <EnterpriseBanner />}
    </>
  );
};

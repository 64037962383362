import {
  faBookOpen,
  faBriefcase,
  faBullseyeArrow,
  faCog,
  faComments,
  faExclamationSquare,
  faHandsHelping,
  faPalette,
  faPencilPaintbrush,
  faStar,
  faTools,
  faUsers,
} from '@fortawesome/pro-solid-svg-icons';
import cx from 'classnames';
import { useRef, useState } from 'react';
import { routes } from '~/constants';
import { useCurrentUser } from '~/root/Auth';
import { HeaderLink as HeaderLinkType } from '../types';
import { HeaderLink } from './HeaderLink';

export const HeaderLinks = ({ hover }: { hover: string }) => {
  const user = useCurrentUser();
  const actualLinks = user ? authorizedHeaderLinks : unauthorizedHeaderLinks;

  const pendingPopup = useRef<string | null>(null);
  const [activePopup, setActivePopup] = useState<string | null>(null);

  return (
    <>
      {actualLinks.map(({ title, path, subLinks, type }) => {
        const className = title.replace(' ', '-').toLowerCase();

        if (subLinks) {
          const menuAttributes = { type, subLinks };

          return (
            <HeaderLink
              key={title}
              className={className}
              title={title}
              active={activePopup === title}
              setPending={() => {
                pendingPopup.current = title;
                if (!activePopup) {
                  setActivePopup(title);
                }
              }}
              unsetPending={() => (pendingPopup.current = null)}
              setActive={() => setActivePopup(pendingPopup.current)}
              onClick={() => setActivePopup((prev) => (prev ? null : title))}
              hover={hover}
              menuAttributes={menuAttributes}
            />
          );
        }

        return (
          <li className={cx('header__link', className, hover)} key={title}>
            {path && <a href={path}>{title}</a>}
          </li>
        );
      })}
    </>
  );
};

export const authorizedHeaderLinks: HeaderLinkType[] = [
  { title: 'Get a Dedicated team', path: routes.public.dedicated_team },
  { title: 'Enterprise solution', path: routes.public.enterprises },
];

const unauthorizedHeaderLinks: HeaderLinkType[] = [
  { title: 'Examples', path: routes.public.examples },
  { title: 'Pricing', path: routes.public.pricing.slides },
  {
    title: 'Our Services',
    subLinks: [
      {
        icon: faTools,
        label: 'Presentation services',
        componentName: 'PresentationServices',
        to: routes.public.services,
      },
      {
        icon: faCog,
        label: 'Dedicated team',
        componentName: 'DedicatedTeam',
        to: routes.public.dedicated_team,
      },
      {
        icon: faBriefcase,
        label: 'Enterprise solution',
        componentName: 'EnterpriseSolution',
        to: routes.public.enterprises,
      },
      { separator: true, separatorKey: 'separatorOurServices' },
      {
        icon: faPencilPaintbrush,
        label: 'Branded & master template',
        componentName: 'BrandedMasterTemplate',
        to: routes.public.master_template,
      },
      {
        icon: faPalette,
        label: 'Extra design services',
        componentName: 'ExtraDesignService',
      },
    ],
  },
  { title: 'Security', path: routes.public.security },
  {
    title: 'Reviews',
    subLinks: [
      { icon: faStar, label: 'Ratings & feedback', to: routes.public.reviews.ratings },
      { icon: faComments, label: 'Customer stories', to: routes.public.reviews.stories },
    ],
    type: 'compact',
  },
  {
    title: 'About us',
    subLinks: [
      {
        icon: faBullseyeArrow,
        label: 'Our purpose',
        componentName: 'OurPurpose',
        to: routes.public.about.purpose,
      },
      {
        icon: faHandsHelping,
        label: 'Our impact',
        componentName: 'OurImpact',
        to: routes.public.about.impact,
      },
      {
        icon: faBookOpen,
        label: 'Our story',
        componentName: 'OurStory',
        to: routes.public.about.story,
      },
      {
        icon: faUsers,
        label: 'Our team',
        componentName: 'OurTeam',
        to: routes.public.about.team,
      },
      { separator: true, separatorKey: 'separatorAboutUs' },
      {
        icon: faExclamationSquare,
        label: 'Code of conduct',
        componentName: 'CodeOfConduct',
        to: routes.public.about.conduct,
      },
    ],
  },
];

import { Route, Switch, Redirect } from 'react-router-dom';

import { routes } from '~/constants';

import { RequestResetLink } from './RequestResetLink';
import { SetNewPassword } from './SetNewPassword';

export const PasswordRoutes = () => (
  <Switch>
    <Route exact path={routes.reset_password} component={RequestResetLink} />
    <Route exact path={`${routes.reset_password}/:token/:email`} component={SetNewPassword} />

    <Redirect from="*" to="/" />
  </Switch>
);

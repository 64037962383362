import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { ReactNode, useMemo } from 'react';
import {
  Switcher,
  useModalContext,
  Button,
  Link,
  Card,
  Tooltip,
  IconBox,
} from '~/common/components';
import { cx, formatDateShort, formatNumber, pluralize } from '~/common/utils';
import { routes } from '~/constants';
import { BookMeetingModal } from '../../BookMeetingModal';
import { BillingStatsItem, Subscription } from '../../domain';
import { useBillingData, useTopupSubscription } from '../../hooks';
import { CancelSubscription } from '../CancelSubscription';
import { ManualTopup } from './ManualTopup';

const cardWithModalOpenerClassNames =
  'flex gap-[12px] flex-col mdo:flex-row mdo:items-end mdo:justify-between';
const containerClassNames = 'inline-flex flex-col md:flex-row gap-3';

export const General = () => {
  const { subscription } = useBillingData();

  return subscription ? (
    <SubscriptionCustomerCards subscription={subscription} />
  ) : (
    <NoSubscriptionCustomerCards />
  );
};

const SubscriptionCustomerCards = ({ subscription }: { subscription: Subscription }) => {
  const { modalOpener } = useModalContext();
  const { creditBalance } = useBillingData();

  const subscriptionDetails = useMemo(() => {
    if (subscription.endsAt) {
      return `Ends: ${formatDateShort(subscription.endsAt)}`;
    }

    if (subscription.startsAt) {
      return `Starts: ${formatDateShort(subscription.startsAt)}`;
    }

    if (subscription.renewsAt) {
      return `Renews: ${formatDateShort(subscription.renewsAt)}`;
    }
  }, [subscription.endsAt, subscription.renewsAt, subscription.startsAt]);

  const toggleSubscriptionTopupMutation = useTopupSubscription(subscription.id);

  const onBook = () => {
    window.dataLayer?.push({
      event: 'formSubmit',
      category: 'corporate-lead',
      action: 'form-submit',
      label: 'billing-page',
    });
  };

  const toggleAutoTopup = (value: boolean) => {
    toggleSubscriptionTopupMutation.mutateAsync({ topUp: value });
  };

  return (
    <div className={cx(containerClassNames, 'flex-wrap')}>
      <Card className={cx(cardWithModalOpenerClassNames, 'grow')}>
        <div>
          <CardHeader title="Current balance" />
          <p className="font-brand-h4 text-text-500 whitespace-nowrap">{`${formatNumber(
            creditBalance,
          )} credit${pluralize(creditBalance)}`}</p>
        </div>
        {(!subscription.products.retainer || subscription.products.retainer?.credits === null) && (
          <div>
            <Link size="m" to={routes.profile.billing.credits} className="mt-[12px]">
              Get more credits
            </Link>
          </div>
        )}
      </Card>
      <Card className={cx(cardWithModalOpenerClassNames, 'grow-[2] min-w-0')}>
        <div>
          <CardHeader title="Subscription" details={subscriptionDetails} />
          <span
            className={cx('font-brand-h4 whitespace-nowrap', {
              'text-secondary-400': subscription.status === 'Active',
              'text-error-300': subscription.status === 'Cancelled',
            })}
          >
            {subscription.products.all.map(({ plan: { name } }) => name).join(' & ')}
          </span>
        </div>
        <div>
          {subscription.isCancellable ? (
            <Button
              color="text"
              size="m"
              className="mt-[12px]"
              onClick={modalOpener(CancelSubscription)}
            >
              Cancel subscription
            </Button>
          ) : (
            <Button
              color="text"
              size="m"
              className="mt-[12px]"
              onClick={modalOpener(BookMeetingModal, {
                title: 'Manage subscription',
                message:
                  "Want to change or cancel your subscription type? Book a call with an account manager, and we'll get in touch ASAP.",
                contactForm: 'subscriptions-manage-subscription',
                onBook,
              })}
            >
              Manage subscription
            </Button>
          )}
        </div>
      </Card>
      {subscription.products.retainer && subscription.products.retainer?.credits !== null && (
        <Card className={cx(cardWithModalOpenerClassNames, 'grow-[2] shrink-0 min-w-0 ')}>
          <div className="inline-flex flex-col">
            <CardHeader
              title="Top-up balance"
              hint="You can manually add credits to your subscription in case you used all of them before next automatical renewal"
            />
            <Switcher
              title="Automatically top-up my subscription"
              className="flex-nowrap py-[4px]"
              value={!!subscription.topUp}
              onChange={toggleAutoTopup}
            />
          </div>
          <div>
            <Button color="text" size="m" className="mt-[12px]" onClick={modalOpener(ManualTopup)}>
              Manual top-up
            </Button>
          </div>
        </Card>
      )}
    </div>
  );
};

const NoSubscriptionCustomerCards = () => {
  const {
    creditBalance,
    stats: {
      orders: { created, completed },
    },
  } = useBillingData();

  return (
    <div className={containerClassNames}>
      <Card className="w-full">
        <CardHeader title="Current balance" />
        <p className="font-brand-h4 text-text-500">
          {formatNumber(creditBalance)}{' '}
          <span className="font-brand-b2">{`credit${pluralize(creditBalance)}`}</span>
        </p>
        <Link to={routes.profile.billing.credits} className="mt-[12px]">
          Get more credits
        </Link>
      </Card>
      <StatsCard title="Orders created" stats={created} />
      <StatsCard title="Orders completed" stats={completed} />
    </div>
  );
};

const StatsCard = ({ title, stats }: { title: string; stats: BillingStatsItem }) => {
  return (
    <Card className="w-full">
      <CardHeader title={title} />
      <span className="font-brand-h4 text-text-500">{stats.thisMonth}</span>
      <p className="font-brand-b2 mt-[12px]">
        {stats.diff === 0 ? (
          'Same as'
        ) : (
          <>
            <span className={stats.diff > 0 ? 'text-secondary-400' : 'text-error-300'}>
              {`${stats.diff ? '+' : ''}${stats.diff} `}
            </span>
            from the
          </>
        )}{' '}
        last month
      </p>
    </Card>
  );
};

const CardHeader = ({
  title,
  hint,
  details,
  className,
}: {
  title: ReactNode;
  hint?: string;
  details?: string;
  className?: string;
}) => (
  <div
    className={cx(
      'flex justify-between items-center w-full text-text-400 mb-[12px] gap-x-1',
      className,
    )}
  >
    <h4 className="font-brand-l2 whitespace-nowrap flex items-center gap-x-[4px]">
      {title}
      {hint && (
        <Tooltip content={hint} placement="right">
          <IconBox size="s" icon={faQuestionCircle} />
        </Tooltip>
      )}
    </h4>
    <span className="font-brand-b2 whitespace-nowrap">{details}</span>
  </div>
);

import { useCurrentUser } from '../../../../Auth';
import { CreditBalanceMenu } from './CreditBalanceMenu';
import { CreditOfferMenu } from './CreditOfferMenu';

type CustomMenuProps = {
  onClick?: () => void;
};

export const Credits = (props: CustomMenuProps) => {
  const user = useCurrentUser();

  return user?.purchasedPackages ? (
    <CreditBalanceMenu {...props} />
  ) : (
    <CreditOfferMenu {...props} />
  );
};

import ReactGA from 'react-ga4';

const pageView = () => {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search,
  });
};

const setExperiments = (experiments) => {
  Object.keys(experiments).forEach((experimentId) => {
    ReactGA.ga('set', 'exp', `${experimentId}.${experiments[experimentId]}`);
  });
  pageView();
};

const gaSend = (hitType, eventCategory, eventAction, eventLabel) => {
  ReactGA.send({
    hitType,
    eventCategory,
    eventAction,
    eventLabel,
  });
};

export { pageView, setExperiments, gaSend };

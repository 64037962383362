import { faCoin } from '@fortawesome/pro-solid-svg-icons';
import cx from 'classnames';
import { IconBox, Link } from '~/common/components';
import { EnterpriseBanner } from '~/components/partials/SubscriptionBanner';
import { routes } from '~/constants';
import { useCurrentUser } from '../../../../Auth';
import { useNavigation } from '../../../context';

type Props = {
  onClick?: () => void;
};

export const CreditOfferMenu = ({ onClick }: Props) => {
  const { isFromHeader } = useNavigation();
  const user = useCurrentUser();

  if (!user?.manageCredits) {
    return null;
  }

  if (isFromHeader && user.subscription) {
    return <EnterpriseBanner />;
  }

  return (
    <div
      className={cx('my-profile-menu__item', 'my-profile-menu__item--column bg-white', {
        'my-profile-menu__item--with-borders': isFromHeader,
      })}
    >
      <div className="menu-item-label">
        <Link.Base to={routes.profile.billing.credits} onClick={onClick}>
          <IconBox
            className={cx('my-profile-menu-icon h-2 w-2', {
              'my-profile-menu-icon--user-menu': isFromHeader,
            })}
            icon={faCoin}
          />
        </Link.Base>

        <p className="my-profile-menu__additional-title">
          <span className="bold">Save up to 10% </span> on your orders
        </p>
      </div>

      <div className="my-profile-menu__additional-link">
        <Link.Base
          className="button-text--blue"
          to={routes.profile.billing.credits}
          onClick={onClick}
        >
          Buy credit pack
        </Link.Base>
      </div>
    </div>
  );
};

import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { MouseEventHandler, ReactNode } from 'react';
import { toast, Toast as TToast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { cx, ignoreHandled } from '~/common/utils';
import { IconButton } from '../IconContainers';

import styles from './Toast.module.scss';

export type ToastProps = {
  title?: string;
  message?: ReactNode;
  onClick?: () => void;
  link?: string;
  duration?: number;
};

export type ToastType = 'success' | 'info' | 'warning' | 'error';

const makeToast =
  (type: ToastType) =>
  ({ duration, ...props }: ToastProps) =>
    toast.custom(
      ({ type: ttype, message, ...toastProps }) => (
        <ToastContent {...toastProps} type={type} {...props} title={props.title} />
      ),
      { duration },
    );

export const Toast = {
  error: makeToast('error'),
  info: makeToast('info'),
  success: makeToast('success'),
  warning: makeToast('warning'),
};

interface ToastContentProps extends ToastProps, Omit<TToast, 'type' | 'message'> {
  type: ToastType;
}

const ToastContent = ({ title, message, type, link, id, onClick }: ToastContentProps) => {
  const handleClick = () => {
    onClick && onClick();
    toast.remove(id);
  };

  const handleDelete: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    toast.remove(id);
  };

  return (
    <Wrapper link={link}>
      <div
        className={cx(
          `flex justify-between gap-1
           px-2 py-[12px] min-w-[280px] max-w-[400px] 
           rounded border border-solid
           font-brand-b2`,
          styles[type],
        )}
        onClick={ignoreHandled(handleClick)}
      >
        <div className="flex flex-col gap-[4px]">
          {title && <h6 className="font-brand-h6">{title}</h6>}
          {typeof message === 'string' ? <p>{message}</p> : message}
        </div>
        <IconButton
          data-stop-propagation
          className={cx('w-3 h-3 mt-[-4px] mr-[-4px] cursor-pointer mb-auto')}
          icon={faTimes}
          onClick={handleDelete}
        />
      </div>
    </Wrapper>
  );
};

function Wrapper({ link, children }: { link?: string; children: React.ReactNode }) {
  return link ? (
    <Link className="contents" to={link}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
}

import { Redirect, Route, Switch } from 'react-router-dom';

import { Spinner } from '~/components/loaders/Spinner';
import { routes } from '~/constants';
import { useCountries } from '~/hooks';
import { useBilling, useCreditPackages } from '~/pages/My-profile/components/Billing/hooks';

import { BillingMenu, withBillingMenu } from './BillingMenu';
import { CreditsPackagesPage, PurchaseCreditsPackage } from './CreditsPackagesPages';
import { Overview } from './Overview';
import { PaymentsHistory } from './PaymentsHistory';
import { Subscription, PurchaseSubscription, ThankYou } from './Subscription';

export const Billing = withBillingMenu(() => {
  // prefetch country codes
  useCountries();
  const billing = useBilling();
  const creditPackages = useCreditPackages();

  if (!billing.data || !creditPackages.data)
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Spinner />
      </div>
    );

  return (
    <div className="relative">
      <BillingMenu />

      <Switch>
        <Route exact path={routes.profile.billing.overview} component={Overview} />

        <Route exact path={routes.profile.billing.payments_history} component={PaymentsHistory} />

        <Route exact path={routes.profile.billing.credits} component={CreditsPackagesPage} />

        <Route
          exact
          path={`${routes.profile.billing.credits_purchase}/:id`}
          component={PurchaseCreditsPackage}
        />

        {!billing.data.subscription?.products.retainer && (
          <Route exact path={routes.profile.billing.subscription} component={Subscription} />
        )}

        {!billing.data.subscription && (
          <Route
            exact
            path={`${routes.profile.billing.subscription_purchase}/:id/:period?`}
            component={PurchaseSubscription}
          />
        )}

        {billing.data.subscription && (
          <Route exact path={routes.profile.billing.thank_you} component={ThankYou} />
        )}

        <Redirect to={routes.profile.billing.overview} />
      </Switch>
    </div>
  );
});

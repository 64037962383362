import PropTypes from 'prop-types';
import { PureComponent } from 'react';
// import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { CSSTransition } from 'react-transition-group';

import './drawer.scss';

const MOBILE_HEADER_HEIGHT = 66;

class Drawer extends PureComponent {
  static propTypes = {
    children: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    isDrawerFixed: PropTypes.bool.isRequired,
    anchor: PropTypes.string,
  };

  static defaultProps = {
    anchor: 'right',
  };

  state = {
    scrollToTop: 0,
  };

  componentDidMount() {
    document.addEventListener('scroll', this.handleOnScroll);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    document.body.style.overflow = nextProps.open ? 'hidden' : 'unset';
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnClick = () => {
    this.props.close();
  };

  handleOnScroll = () => {
    if ((window.pageYOffset || window.scrollY) <= MOBILE_HEADER_HEIGHT) {
      this.setState({
        scrollToTop: window.pageYOffset || window.scrollY,
      });
    }
  };

  render() {
    const { open, isDrawerFixed } = this.props;

    return (
      <CSSTransition
        in={open}
        classNames="drawer-menu-transition--right"
        timeout={225}
        mountOnEnter
        unmountOnExit
      >
        <div
          className="drawer-menu"
          style={{
            top: !isDrawerFixed
              ? MOBILE_HEADER_HEIGHT - this.state.scrollToTop
              : MOBILE_HEADER_HEIGHT,
          }}
        >
          {this.props.children}
        </div>
      </CSSTransition>
    );
  }
}

export default Drawer;

import { useCurrentUser } from '~/root/Auth';
import { SubNavigation } from '../components';
import { Credits } from '../components/banners';
import { ProBanner } from '../components/banners/Pro';
import { NavigationLink } from '../components/NavigationLink';
import { NavigationSection } from '../components/NavigationSection';
import { StyleTemplatesLink } from '../components/StyleTemplatesLink';
import { useNavigation } from '../context';

// This menu is also used for subscriptions with 0 capacity points
export const RegularNavigation = () => {
  const { config, isFromHeader, isMenuCollapsed } = useNavigation();
  //TODO change to asserted hook while redesigning header
  const user = useCurrentUser();

  return (
    <>
      <NavigationSection>
        <NavigationLink {...config.links.myOrders} />
        <NavigationLink {...config.links.myTeam} />
        <NavigationLink {...config.links.billing} />
        <NavigationLink {...config.links.customFields} />
        <StyleTemplatesLink />
      </NavigationSection>
      <NavigationSection>
        <Credits />
      </NavigationSection>
      <NavigationSection>
        <SubNavigation {...config.subMenus.settings}>
          <NavigationLink {...config.links.userDetails} />
          <NavigationLink {...config.links.security} />
        </SubNavigation>
        <NavigationLink {...config.links.needHelp} />
      </NavigationSection>
      {!isMenuCollapsed && !isFromHeader && !user?.subscription && <ProBanner />}
      {isFromHeader && !user?.subscription && <ProBanner className="mx-2" />}
    </>
  );
};

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { ZodError } from 'zod';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Toast } from '~/common/components';
import { useIdParam } from '~/common/hooks';
import { assertQueryData } from '~/common/kits/query';
import { Overwrite, nonNullable } from '~/common/utils';
import { axios } from '~/root';
import { CustomFieldValue } from '../../CustomFields';
import {
  Order,
  SlideWithAnnotations,
  Slides,
  annotationCommentSchema,
  annotationSchema,
  getConfirmationResponseSchema,
  orderSchema,
  accessibleOrderSchema,
  slideAnnotationsSchema,
  slidesSchema,
} from '../domain';
import { slidesNewness } from '../utils';

export type JoinedOrder = ReturnType<typeof useFullOrderData>;

export const ordersQueryKey = 'orders';

export const useOrder = () => {
  const id = useIdParam();

  return useQuery({
    queryKey: [ordersQueryKey, id],
    queryFn: async ({ signal }) => {
      // TODO axios instance should support getting schema param like decoder on IP
      const [order, slides, slideAnnotations] = await Promise.all([
        axios.get(`/v1/orders/${id}`, { signal }).then((res) => orderSchema.parse(res.data.order)),
        axios
          .get(`/v1/orders/${id}/slides`, { signal })
          .then((res) => {
            const slides = slidesSchema.parse(res.data);
            // TODO ugh...
            slidesNewness.init.versions(slides);
            return slides;
          })
          .catch((error) => {
            if (error instanceof ZodError) {
              throw error;
            }
            return [] as Slides;
          }),
        axios
          .get(`/v1/orders/${id}/annotations`, { signal })
          .then((res) => {
            const slides = slideAnnotationsSchema.parse(res.data);
            // TODO ugh...
            slidesNewness.init.annotations(slides.slideAnnotations);
            return slides;
          })
          // I have no idea why TypeScript makes a union from this catch
          .catch((error) => {
            if (error instanceof ZodError) {
              throw error;
            }
            return { slideAnnotations: [] as SlideWithAnnotations[], maxAnnotationIndex: 0 };
          }),
      ]);
      return {
        ...order,
        // so here's the casting for it
        ...(slideAnnotations as {
          slideAnnotations: SlideWithAnnotations[];
          maxAnnotationIndex: number;
        }),
        slideList: slides,
      };
    },
  });
};

export const useBothOrdersData = () => assertQueryData(useOrder());

export const useFullOrderData = () => {
  const order = useBothOrdersData();
  if (order.isAccessible) {
    return order;
  }
  throw new Error("Order is asserted as accessible, but it's not");
};

// TODO consider making a generic update hook generator
export const useUpdateOrderCache = () => {
  const client = useQueryClient();
  const id = useIdParam();

  return {
    cancelQuery: () => client.cancelQueries([ordersQueryKey, id]),
    getQuery: () => nonNullable(client.getQueryData<JoinedOrder>(['orders', id])),
    setQuery: (stateOrCb?: ((order: JoinedOrder) => JoinedOrder) | JoinedOrder) => {
      return client.setQueryData<JoinedOrder>(
        ['orders', id],
        stateOrCb instanceof Function ? (prev) => prev && stateOrCb(prev) : stateOrCb,
      );
    },
  };
};

type UpdateOrderPayload = Partial<
  Overwrite<Order, { customFields: Record<string, CustomFieldValue> }>
>;

export const useUpdateOrder = () => {
  const id = useIdParam();
  const { setQuery } = useUpdateOrderCache();

  return useMutation({
    mutationFn: (order: Pick<UpdateOrderPayload, 'title' | 'customFields'>) => {
      return axios.put(`/v1/orders/${id}`, order).then((res) =>
        setQuery((prev) => ({
          ...prev,
          ...accessibleOrderSchema.parse(res.data.order),
        })),
      );
    },
  });
};

export const useToggleConfidential = () => {
  const id = useIdParam();
  const client = useQueryClient();
  const { cancelQuery, getQuery, setQuery } = useUpdateOrderCache();
  return useMutation({
    mutationFn: () =>
      axios
        .post(`/v1/orders/${id}/change-confidential-state`)
        .then((res) => accessibleOrderSchema.parse(res.data.order)),
    onMutate: async () => {
      await cancelQuery();
      const prev = getQuery();
      setQuery((prev) => ({ ...prev, isConfidential: !prev.isConfidential }));
      return prev;
    },
    onSuccess: (order) => {
      setQuery((prev) => ({ ...prev, ...order }));
      Toast.success({
        message: `Order confidential status successfully turned ${
          order.isConfidential ? 'on' : 'off'
        }.`,
      });
      client.invalidateQueries(['orders', 'list']);
    },
  });
};

export const useAccessAlreadyRequested = create<{ requested: number[] }>()(
  persist(() => ({ requested: [] as number[] }), { name: '_access-already-requested' }),
);

export const useRequestAccess = () => {
  const id = useIdParam();
  return useMutation({
    mutationFn: () => axios.post(`/v1/orders/${id}/request-access`),
    onSuccess: () => {
      useAccessAlreadyRequested.setState((prev) => ({ requested: [...prev.requested, id] }));
    },
  });
};

type CreateAnnotationPayload = {
  slideId: number;
  comment: string;
  pos: {
    x: number;
    y: number;
  };
};

export const useCreateAnnotation = () => {
  const { setQuery } = useUpdateOrderCache();

  return useMutation({
    mutationFn: ({ slideId, comment, pos }: CreateAnnotationPayload) => {
      return axios
        .post(`/v1/orders/slides/${slideId}/annotations`, { comment, pos })
        .then((res) => {
          const annotation = annotationSchema.parse(res.data.annotation);
          setQuery((prev) => {
            return setSubscribedTo(true, {
              ...prev,
              slideAnnotations: prev.slideAnnotations.map((slide) =>
                slide.id === slideId
                  ? {
                      ...slide,
                      annotations: [...slide.annotations, annotation],
                    }
                  : slide,
              ),
              // for figuring out not yet created annotation index
              maxAnnotationIndex: annotation.index,
            });
          });
          return annotation;
        });
    },
  });
};

export const useDeleteAnnotation = () => {
  const id = useIdParam();
  const { cancelQuery, getQuery, setQuery } = useUpdateOrderCache();

  return useMutation({
    mutationFn: ({ annotationId }: { slideId: number; annotationId: number }) => {
      return axios.delete(`/v1/orders/${id}/annotations/${annotationId}`);
    },
    onMutate: async ({ slideId, annotationId }) => {
      await cancelQuery();
      const prev = getQuery();
      const nextSlideAnnotations = prev.slideAnnotations.map((slide) =>
        slide.id === slideId
          ? {
              ...slide,
              annotations: slide.annotations.filter((annotation) => annotation.id !== annotationId),
            }
          : slide,
      );
      setQuery((prev) => {
        return setSubscribedTo(true, {
          ...prev,
          slideAnnotations: nextSlideAnnotations,
          // for figuring out not yet created annotation index
          maxAnnotationIndex: Math.max(
            0,
            ...(nextSlideAnnotations
              .find((slide) => slide.id === slideId)
              ?.annotations.map(({ index }) => index) ?? []),
          ),
        });
      });
      return prev;
    },
    // rollback
    onError: (_error, _variables, prev) => setQuery(prev),
  });
};

type PostAnnotationCommentPayload = {
  slideId: number;
  annotationId: number;
  comment: string;
};

export const usePostAnnotationComment = () => {
  const { setQuery } = useUpdateOrderCache();

  return useMutation({
    mutationFn: ({ slideId, annotationId, comment }: PostAnnotationCommentPayload) => {
      return axios
        .post(`/v1/orders/annotations/${annotationId}/comments`, { comment })
        .then((res) => {
          const comment = annotationCommentSchema.parse(res.data.comment);
          setQuery((prev) => {
            return setSubscribedTo(true, {
              ...prev,
              slideAnnotations: prev.slideAnnotations.map((slide) => {
                return slide.id === slideId
                  ? {
                      ...slide,
                      annotations: slide.annotations.map((annotation) =>
                        annotation.id === annotationId
                          ? {
                              ...annotation,
                              comments: [...annotation.comments, comment],
                            }
                          : annotation,
                      ),
                    }
                  : slide;
              }),
            });
          });
        });
    },
  });
};

type ResolveAnnotationPayload = {
  slideId: number;
  annotationId: number;
  resolved: boolean;
};

export const useResolveAnnotation = () => {
  const id = useIdParam();
  const { cancelQuery, getQuery, setQuery } = useUpdateOrderCache();

  return useMutation({
    mutationFn: ({ annotationId, resolved }: ResolveAnnotationPayload) => {
      return axios.put(`/v1/orders/${id}/annotations/${annotationId}`, { resolved });
    },
    onMutate: async ({ slideId, annotationId, resolved }) => {
      await cancelQuery();
      const prev = getQuery();
      setQuery((prev) => {
        return setSubscribedTo(true, {
          ...prev,
          slideAnnotations: prev.slideAnnotations.map((slide) =>
            slide.id === slideId
              ? {
                  ...slide,
                  annotations: slide.annotations.map((annotation) =>
                    annotation.id === annotationId ? { ...annotation, resolved } : annotation,
                  ),
                }
              : slide,
          ),
        });
      });
      return prev;
    },
    onError: (_error, _variables, prev) => setQuery(prev),
  });
};

export const useGetConfirmation = () => {
  const id = useIdParam();

  return useMutation({
    mutationFn: () => {
      return axios
        .post(`/v1/orders/${id}/confirm`)
        .then((res) => getConfirmationResponseSchema.parse(res.data));
    },
  });
};

type PayWithCredits = {
  method: 'credits';
  payload?: undefined;
};

type PayWithCreditsTopup = {
  method: 'recharge';
  payload: {
    method: number;
  };
};

type PayWithPackage = {
  method: 'package';
  payload: {
    package: number;
    method: number;
  };
};

type PayWithCard = {
  method: 'card';
  payload: {
    method: number;
  };
};

export type ConfirmPaymentData =
  | PayWithCard
  | PayWithPackage
  | PayWithCreditsTopup
  | PayWithCredits
  | null;

export const useConfirmPayment = () => {
  const id = useIdParam();
  const { setQuery } = useUpdateOrderCache();

  return useMutation({
    mutationFn: (data: ConfirmPaymentData) => {
      const handler = (res: AxiosResponse) => {
        const next = accessibleOrderSchema.parse(res.data.order);
        setQuery((prev) => ({ ...prev, ...next }));
        return next;
      };

      if (!data) {
        return axios.post(`/v1/orders/${id}/payment/`).then(handler);
      }

      return axios.post(`/v1/orders/${id}/payment/${data.method}`, data.payload).then(handler);
    },
  });
};

export const setSubscribedTo = (isSubscribed: boolean, prev: JoinedOrder): JoinedOrder => ({
  ...prev,
  members: {
    ...prev.members,
    you: {
      ...prev.members.you,
      isSubscribed,
    },
    members: prev.members.members.map((member) => {
      return 'id' in member && member.id === prev.members.you.id
        ? { ...member, isSubscribed }
        : member;
    }),
  },
});

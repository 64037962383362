import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import cx from 'classnames';
import React, { useEffect, useRef } from 'react';

import { IconBox } from '~/components';
import { useScreenSize } from '~/hooks/useHeaderPopup';
import { HeaderLinkTypes, Sublinks } from '../types';
import { HeaderSubmenu } from './HeaderSubmenu';

interface HeaderLinkProps {
  hover: string;
  className?: string;
  title: string;
  active: boolean;
  setActive: () => void;
  setPending: () => void;
  unsetPending: () => void;
  onClick: () => void;
  menuAttributes: {
    type?: HeaderLinkTypes;
    subLinks: Sublinks[];
  };
}

export const HeaderLink = ({
  hover,
  className,
  title,
  active,
  setActive,
  setPending,
  unsetPending,
  onClick,
  menuAttributes,
}: HeaderLinkProps) => {
  const popupRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);
  const windowWidth = useScreenSize();
  const mobile = windowWidth === 'md' || windowWidth === 'sm';

  useEffect(() => {
    const handleMouseOut = (e: MouseEvent) => {
      const popup = popupRef.current;
      const trigger = triggerRef.current;

      if (!trigger || !popup) {
        return;
      }

      // prevent unsetting pending sibling link
      if (!(e.target as HTMLElement).closest('[data-link]')) {
        unsetPending();
      }

      setTimeout(() => {
        if (!popup.matches(':hover') && !trigger.matches(':hover')) {
          setActive();
        }
      }, 250);
    };

    document.addEventListener('mouseover', handleMouseOut);

    return () => {
      document.removeEventListener('mouseover', handleMouseOut);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <li className={cx('header__link flex flex-col items-center relative', className)} key={title}>
      <div className="header__link-container" ref={triggerRef} data-link>
        <button
          className={cx('header__link-btn flex items-center', hover)}
          onMouseOver={mobile ? undefined : setPending}
          onClick={onClick}
        >
          <h3 className="mr-1 font-normal leading-12">{title}</h3>
          <IconBox
            className={cx(
              'header__sub-menu transition-transform duration-300 ease-in',
              active ? '-rotate-180' : 'rotate-0',
            )}
            icon={faChevronDown}
          />
        </button>
      </div>
      <HeaderSubmenu {...menuAttributes} visible={active} ref={popupRef} />
    </li>
  );
};

import { faTrashCan, faFile } from '@fortawesome/pro-light-svg-icons';
import { uploadFileIcon } from '~/assets/images/icons';
import { cx } from '~/common/utils';
import { CircularProgress } from '../CircularProgress';
import { IconBox } from '../IconContainers';
import { Button } from '../Interactives';
import { FileUpload, FileUploadProps, NonEmptyItemRendererProps } from './FileUpload';

const nonEmptyRenderer = ({
  loading,
  clear,
  file,
  disabled,
  progress,
}: NonEmptyItemRendererProps) => {
  return (
    <>
      <div className="mx-[12px] w-4 h-4 flex items-center justify-center">
        {loading ? (
          <CircularProgress percentage={progress} size={24} strokeWidth={2} />
        ) : (
          <IconBox size="l" icon={faFile} className="text-other-400" />
        )}
      </div>
      <span className={cx('text-text-500 flex-grow truncate')}>{file?.name}</span>
      {!disabled && (
        <Button.Icon
          icon={faTrashCan}
          size="m"
          className="mx-[12px] text-other-500"
          onClick={clear}
        />
      )}
    </>
  );
};

const placeholderRenderer = () => {
  return (
    <div className="flex items-center gap-1">
      <img src={uploadFileIcon} />
      <span className="font-brand-b2 text-text-300">Choose a file</span>
    </div>
  );
};

export const FileUploadField = (
  props: Omit<FileUploadProps, 'itemRenderer' | 'placeholderRenderer'>,
) => {
  return (
    <FileUpload
      {...props}
      itemRenderer={nonEmptyRenderer}
      placeholderRenderer={placeholderRenderer}
    />
  );
};

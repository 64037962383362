import { RefObject } from 'react';

import { CSSTransition } from 'react-transition-group';

import Drawer from '~/components/ui/drawer';

import { useProperClickOutside } from '~/hooks';
import { Navigation } from '~/root';

import './_Drawer-section.scss';

interface DrawerSectionProps {
  isUserMenuOpen: boolean;
  isDrawerFixed: boolean;
  closeDrawer: () => void;
  logout: () => void;
  triggerRef: RefObject<HTMLLIElement>;
}

const DrawerSection = ({
  isUserMenuOpen,
  isDrawerFixed,
  closeDrawer,
  triggerRef,
}: DrawerSectionProps) => {
  const drawerRef = useProperClickOutside(closeDrawer, triggerRef);

  return (
    <div className="absolute" ref={drawerRef as unknown as RefObject<HTMLDivElement>}>
      <Drawer isDrawerFixed={isDrawerFixed} open={isUserMenuOpen} close={closeDrawer}>
        <CSSTransition
          in={isUserMenuOpen}
          classNames="drawer-content-transition"
          timeout={225}
          mountOnEnter
          unmountOnExit
        >
          <Navigation isFromHeader />
        </CSSTransition>
      </Drawer>
    </div>
  );
};

export default DrawerSection;

import { cx } from '~/common/utils';
import { LogoutItem } from './components';
import { useNavigation, withNavigation } from './context';
import { useUserRole } from './hooks';
import { ManagerNavigation, RegularNavigation, SubscriptionNavigation } from './roles';

import './styles.scss';
import { NavigationProps } from './types';

export const Navigation = withNavigation(({ className }: NavigationProps) => {
  const { isFromHeader, isMenuCollapsed } = useNavigation();

  const userRole = useUserRole();

  return (
    <div
      className={cx('my-profile-menu', className, {
        'my-profile-menu--with-triangle': isFromHeader,
        collapsed: !isFromHeader && isMenuCollapsed,
      })}
    >
      {userRole === 'subscription' && <SubscriptionNavigation />}
      {userRole === 'manager' && <ManagerNavigation />}
      {userRole === 'regular' && <RegularNavigation />}
      {isFromHeader && <LogoutItem />}
    </div>
  );
});

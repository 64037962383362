import { FieldProps } from 'formoid';
import { ChangeEvent, InputHTMLAttributes, MouseEvent, useMemo, useRef, useState } from 'react';
import { Errors } from '~/common/components';
import { cx } from '~/common/utils';
import { UploadedFile } from '~/root/domain';
import { defaultMimeTypes } from './constants';
import styles from './styles.module.scss';

type Elem = HTMLInputElement | HTMLDivElement;

export type FileUploadProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type' | 'onChange' | 'size' | 'value' | 'accept'
> &
  Omit<FieldProps<File | null>, 'value'> &
  Partial<{
    file: UploadedFile | null;
    value: string | null;
    loading: boolean;
    size: 'small' | 'regular';
    hintLeft: string;
    hintRight: string;
    acceptedMimeTypes?: string[];
  }> & {
    placeholderRenderer: () => JSX.Element;
    itemRenderer: ({ ...props }: NonEmptyItemRendererProps) => JSX.Element;
    progress: number;
  };

const validateFile = (file: File, mimeTypes: string[]): boolean => mimeTypes.includes(file.type);

export type NonEmptyItemRendererProps = {
  loading?: boolean;
  isError: boolean;
  imagePreviewUrl: string | null;
  file: UploadedFile | null;
  clear: (e: MouseEvent) => void;
  disabled: boolean;
  progress: number;
};

export const FileUpload = ({
  disabled,
  errors,
  touched,
  onChange,
  onBlur,
  title,
  loading,
  hintLeft,
  hintRight,
  size = 'regular',
  className,
  children,
  value: filename,
  file,
  placeholderRenderer,
  itemRenderer,
  acceptedMimeTypes = defaultMimeTypes,
  progress,
  ...props
}: FileUploadProps) => {
  const [draggedOver, setDraggedOver] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(file?.link || null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const inputMimeTypes = useMemo(() => acceptedMimeTypes?.join(', '), [acceptedMimeTypes]);

  const handleFileChange = (e: ChangeEvent<Elem>) => {
    e.preventDefault();
    const files = 'files' in e.target ? e.target.files : [];

    if (!files?.[0] || !validateFile(files[0], acceptedMimeTypes)) {
      // TODO add an error state for this
      return;
    }

    if (imagePreviewUrl) URL.revokeObjectURL(imagePreviewUrl);
    setImagePreviewUrl(URL.createObjectURL(files[0]));
    onChange(files[0]);
  };

  const clear = (e: MouseEvent) => {
    e.preventDefault();
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setImagePreviewUrl(null);
    onChange(null);
  };

  return (
    <label
      className={cx(styles.label, disabled ? 'cursor-default' : 'cursor-pointer')}
      onDragEnter={() => setDraggedOver(true)}
    >
      <input
        ref={inputRef}
        {...props}
        className={cx(
          'opacity-0 absolute',
          draggedOver ? 'z-20 top-0 bottom-0 left-0 right-0' : '-z-10',
        )}
        onChange={handleFileChange}
        onDrop={() => setDraggedOver(false)}
        onDragLeave={() => setDraggedOver(false)}
        type="file"
        accept={inputMimeTypes}
        disabled={disabled || loading}
      />
      {title && (
        <span
          className={cx('block mb-[4px]', size === 'small' ? 'font-brand-b2r' : 'font-brand-b1')}
        >
          {title}
        </span>
      )}
      <div
        className={cx(styles.input, className, 'group', {
          [styles.dragover]: draggedOver,
          [styles.error]: errors,
          [styles.empty]: !file && !draggedOver,
          'text-greyscale-300': disabled,
        })}
      >
        {file
          ? itemRenderer({
              clear,
              loading,
              imagePreviewUrl,
              file,
              isError: !!errors?.length,
              disabled,
              progress,
            })
          : placeholderRenderer()}
      </div>
      {(hintLeft || hintRight) && (
        <div className="flex mt-2 font-brand-b2 text-text-400">
          <span>{hintLeft}</span>
          <span className="ml-auto">{hintRight}</span>
        </div>
      )}
      {errors && <Errors errors={errors} />}
    </label>
  );
};

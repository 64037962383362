import { ReactNode } from 'react';
import { cx } from '~/common/utils';
import { useNavigation } from '../context';

type Props = {
  children: ReactNode;
  className?: string;
};

export const NavigationSection = ({ children }: Props) => {
  const { isFromHeader } = useNavigation();

  return (
    <div
      className={cx(
        'my-profile-menu__section',
        isFromHeader && 'my-profile-menu__section--user-menu',
      )}
    >
      {children}
    </div>
  );
};

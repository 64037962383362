import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { useMemo } from 'react';
import { GenericItem, TableView } from '~/common/kits/table';
import { cx } from '~/common/utils';
import { PaginationItem } from './PaginationItem';
import { getPages, PageItem } from './utils';

interface PaginationProps
  extends Pick<TableView<GenericItem>, 'page' | 'totalPages' | 'total' | 'results'> {
  limit?: number;
  className?: string;
  onClick?: (page: number) => void;
}

export const Pagination = ({
  page,
  totalPages,
  total,
  results,
  limit,
  className,
  onClick,
}: PaginationProps) => {
  const firstPage = page === 1;
  const lastPage = page === totalPages;
  const clickNext = () => onClick?.(page - 1);
  const clickPrevious = () => onClick?.(page + 1);

  const makePage = ({ key, value }: PageItem) => {
    const active = value === page;
    const disabled = typeof value !== 'number';
    const clickPage = () => onClick?.(+value);

    return (
      <PaginationItem
        key={key}
        active={active}
        disabled={disabled}
        onClick={clickPage}
        className={disabled ? 'px-0 min-w-0' : undefined}
      >
        {value}
      </PaginationItem>
    );
  };

  const Pages = getPages(page, totalPages).map(makePage);

  const showedResults = useMemo(() => {
    if (!(limit && results)) {
      return `Showing ${page} page results`;
    }

    const firstShowedItemNumber = limit * (page - 1) + 1;

    if (results === 1) {
      return `Showing ${firstShowedItemNumber} of ${total}`;
    }

    return `Showing ${firstShowedItemNumber}-${limit * (page - 1) + results} of ${total}`;
  }, [limit, page, results, total]);

  return (
    <div
      className={cx(
        'flex flex-col md:flex-row items-center md:justify-between px-2 gap-x-1 gap-y-2',
        className,
      )}
    >
      <span className="text-text-300 font-brand-b2">{showedResults}</span>
      <ul className="flex justify-center space-x-[4px]">
        <PaginationItem disabled={firstPage} onClick={clickNext} icon={faChevronLeft} />
        {Pages}
        <PaginationItem disabled={lastPage} onClick={clickPrevious} icon={faChevronRight} />
      </ul>
    </div>
  );
};

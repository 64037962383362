import {
  arrow,
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  Placement,
  safePolygon,
  shift,
  useDelayGroup,
  useDelayGroupContext,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { cloneElement, ReactNode, useCallback, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { cx, getCSSTransitionClassNames } from '~/common/utils';
import { useArrow } from '../Floating';
import styles from './Tooltip.module.scss';

type Props = {
  content: ReactNode;
  children: JSX.Element;
  color?: 'white' | 'grey' | 'darkgrey' | 'primary';
  className?: string;
  placement?: Placement;
  fallbackPlacements?: Placement[];
  delay?: number;
  compensateOffset?: number;
  noArrow?: boolean;
  clickable?: boolean;
};

export const Tooltip = ({
  content,
  children,
  color = 'primary',
  className,
  placement = 'top',
  fallbackPlacements = ['top', 'bottom', 'left', 'right'],
  delay = 300,
  compensateOffset = 0,
  noArrow = false,
  clickable = false,
}: Props) => {
  const [open, setOpen] = useState(false);

  const arrowRef = useRef<HTMLDivElement>(null);

  // delayGroup
  const { delay: groupDelay, setCurrentId } = useDelayGroupContext();
  const onOpenChange = useCallback(
    (open: boolean) => {
      setOpen(open);

      if (open) {
        setCurrentId(content);
      }
    },
    [content, setCurrentId],
  );

  const {
    context,
    refs,
    middlewareData,
    placement: floatingPlacement,
    floatingStyles,
  } = useFloating({
    placement,
    open,
    // delayGroup
    onOpenChange,
    middleware: [
      offset(12 - compensateOffset),
      flip({ fallbackPlacements }),
      shift({ padding: 8 }),
      arrow({ element: arrowRef }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const { arrowProps, staticSide } = useArrow(arrowRef, floatingPlacement);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      // delayGroup
      delay: groupDelay === 0 ? { open: delay, close: 50 } : groupDelay,
      restMs: 40,
      handleClose: clickable ? safePolygon() : undefined,
    }),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
    // delayGroup
    useDelayGroup(context, { id: content }),
  ]);

  return (
    // TODO use render-props instead of cloneElement to be able to merge
    // multiple ref callbacks from above, make multiRef(ref1, ref2) utility
    // use-case - dropdown popover & tooltip on select fields on the same node
    <>
      {cloneElement(children, getReferenceProps({ ref: refs.setReference, ...children.props }))}
      <FloatingPortal>
        <CSSTransition
          classNames={getCSSTransitionClassNames(styles)}
          in={open && Boolean(content)}
          timeout={300}
          unmountOnExit
          nodeRef={refs.floating}
        >
          <div
            {...getFloatingProps({
              ref: refs.setFloating,
              className: cx(
                styles.tooltip,
                styles[color],
                styles[staticSide],
                className,
                clickable && 'pointer-events-auto',
              ),
              style: floatingStyles,
              onClick: () => setOpen(false),
            })}
          >
            {!noArrow && <div {...arrowProps(middlewareData)} className={styles.arrow} />}
            {content}
          </div>
        </CSSTransition>
      </FloatingPortal>
    </>
  );
};

import {
  autoUpdate,
  FloatingNode,
  FloatingPortal,
  offset,
  Placement,
  size,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFocus,
  useInteractions,
  useListNavigation,
} from '@floating-ui/react';
import { faChevronUp, faTimes } from '@fortawesome/pro-solid-svg-icons';
import React, {
  ChangeEventHandler,
  ComponentProps,
  ReactNode,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CSSTransition } from 'react-transition-group';
import { IconBox, Input } from '~/common/components';
import { useEvent } from '~/common/hooks';
import { Any, cx, getCSSTransitionClassNames, Overwrite } from '~/common/utils';
import styles from './Select.module.scss';

// TODO it might be better to make Select smarter in terms of
// evaluating item height somehow, instead of providing containerClassName
//
// it might be also better to provide itemRenderer with a
// new RegExp(inputValue), that is generated once instead of a plain string,
// that will be converted to that most likely anyway

export type Option<T> = {
  name: string;
  value: T;
};

export type InputFieldProps = {
  // TODO find generic enough variant
  ref: React.ForwardedRef<Any>;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onFocus: React.FocusEventHandler<HTMLInputElement>;
  onBlur: React.FocusEventHandler<HTMLInputElement>;
  onKeyUp: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyDown: React.KeyboardEventHandler<HTMLInputElement>;
  onClick: React.MouseEventHandler<Element>;
  onMouseDown: React.MouseEventHandler<Element>;
  onMouseLeave: React.MouseEventHandler<Element>;
  onPointerDown: React.PointerEventHandler<Element>;
  readOnly: boolean;
  children: ReactNode;
};

export type ItemRendererProps<T> = {
  key: string;
  ref: (node: HTMLElement | null) => void;
  className: string;
  onClick: () => void;
  onFocus: () => void;
  onMouseLeave: () => void;
  onMouseMove: () => void;
  option: T;
  inputValue: string;
};

const defaultItemRenderer = <T extends Option<Any>>({
  option,
  inputValue,
  ...props
}: ItemRendererProps<T>) => (
  <li {...props}>
    <span className="truncate">{option.name}</span>
  </li>
);

// TODO it might be even better to handle not found in defaultItemRenderer
// extra renderer for this looks kinda meh
export type NotFoundRendererProps = { className: string };

const defaultNotFoundRenderer = ({ className }: NotFoundRendererProps) => (
  <li className={className}>Nothing found</li>
);

const defaultOptionsFilter =
  (search: string) =>
  <T,>(option: Option<T>) =>
    option.name.toLowerCase().includes(search);

type NonNullableSelectFactoryProps<T> = {
  nullable?: false;
  onChange: (value: T) => void;
};

type NullableSelectFactoryProps<T> = {
  nullable: true;
  onChange: (value: T | null) => void;
};

export type SelectFactoryProps<T extends Option<Any>, K = T['value']> = {
  value: K | null;
  options: T[];
  inputField: (props: InputFieldProps) => ReactNode;
  itemRenderer?: (props: ItemRendererProps<T>) => ReactNode;
  notFoundRenderer?: (props: NotFoundRendererProps) => ReactNode;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  filterOptions?: (search: string) => (option: T) => boolean;
  noSearch?: boolean;
  theme?: 'light' | 'dark' | 'darken';
  containerClassName?: string;
  placement?: Placement;
  singleIcon?: boolean;
} & (NullableSelectFactoryProps<K> | NonNullableSelectFactoryProps<K>);

export const SelectFactory = <T extends Option<Any>, K = T['value']>({
  value,
  onChange,
  options,
  inputField,
  itemRenderer = defaultItemRenderer,
  notFoundRenderer = defaultNotFoundRenderer,
  onBlur: formOnBlur = () => null,
  filterOptions = defaultOptionsFilter,
  noSearch = false,
  theme = 'light',
  containerClassName,
  placement = 'bottom-start',
  nullable,
  singleIcon,
}: SelectFactoryProps<T, K>) => {
  const [open, setOpen] = useState(false);

  const [searchText, setSearchText] = useState('');

  // kbd nav
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  // kbd nav
  const listRef = useRef<Array<HTMLElement | null>>([]);

  const nodeId = useFloatingNodeId();

  const { optionName, selectedIndex } = useMemo(() => {
    // kbd nav
    const index = options.findIndex((option) => option.value === value);
    const optionName = options[index]?.name ?? '';
    return { optionName, selectedIndex: index === -1 ? null : index };
  }, [options, value]);

  const inputValue = open && !noSearch ? searchText : optionName;

  const { context, refs, floatingStyles } = useFloating<HTMLDivElement>({
    nodeId,
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      size({
        apply({ rects, elements }) {
          elements.floating.style.setProperty(
            '--reference-width',
            `${rects.reference.width - 2}px`,
          );
        },
        padding: 10,
      }),
      offset({ mainAxis: 5 }),
    ],
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    useClick(context, { keyboardHandlers: false }),
    useFocus(context),
    useDismiss(context),
    // kbd nav
    useListNavigation(context, {
      listRef,
      activeIndex,
      selectedIndex,
      onNavigate: setActiveIndex,
      virtual: true,
      loop: true,
    }),
  ]);

  const items =
    open && !noSearch ? options.filter(filterOptions(searchText.toLowerCase())) : options;

  // a hack to have updated form state when validating
  const freshOnBlur = useEvent(formOnBlur);
  const onBlur = (_event: Any) => setTimeout(freshOnBlur, 100);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    // kbd nav
    setActiveIndex(0);
    setOpen(true);
  };

  const handleFocus = () => {
    setSearchText('');
  };

  // kbd nav
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && activeIndex != null && items[activeIndex]) {
      onChange(items[activeIndex].value);
      onBlur(null as Any);
      setOpen(false);
    }
  };

  const inputFieldProps = getReferenceProps({
    ref: refs.setReference,
    value: inputValue,
    onChange: handleChange,
    onFocus: handleFocus,
    onBlur,
    onKeyDown: handleKeyDown,
    readOnly: noSearch,
    children: (
      <>
        {nullable && value && (
          <IconBox
            className="cursor-pointer"
            size="s"
            icon={faTimes}
            onClick={(e) => {
              e.preventDefault();
              onChange(null);
            }}
          />
        )}
        {!singleIcon && !(nullable && value) && (
          <IconBox
            className={cx(styles.chevron, open && styles.chevronActive)}
            icon={faChevronUp}
          />
        )}
      </>
    ),
  }) as Any;

  return (
    <>
      {inputField(inputFieldProps)}
      <FloatingNode id={nodeId}>
        <FloatingPortal>
          <CSSTransition
            classNames={getCSSTransitionClassNames(styles)}
            in={open}
            timeout={200}
            unmountOnExit
            nodeRef={refs.floating}
          >
            <div
              {...getFloatingProps({
                ref: refs.setFloating,
                className: cx(styles.container, containerClassName, {
                  [styles.dark]: theme === 'dark',
                  [styles.darken]: theme === 'darken',
                }),
                style: floatingStyles,
              })}
            >
              <ul className={styles.options}>
                {items.length
                  ? items.map((option, index) =>
                      itemRenderer({
                        option,
                        inputValue: searchText,
                        ...getItemProps({
                          key: String(option.value),
                          ref(node) {
                            listRef.current[index] = node;
                          },
                          onClick(event) {
                            onChange(option.value);
                            onBlur(event as Any);
                            setOpen(false);
                          },
                          className: cx(styles.option, {
                            [styles.optionActive]: activeIndex === index,
                          }),
                        }),
                      } as Any),
                    )
                  : notFoundRenderer({ className: styles.notFound })}
              </ul>
            </div>
          </CSSTransition>
        </FloatingPortal>
      </FloatingNode>
    </>
  );
};

export type SelectProps<T extends Option<Any>, K = T['value']> = Overwrite<
  ComponentProps<typeof Input>,
  Omit<SelectFactoryProps<T, K>, 'inputField'>
>;

export const Select = <T extends Option<Any>, K = T['value']>({
  value,
  onChange,
  onBlur,
  options,
  noSearch,
  placement,
  itemRenderer,
  notFoundRenderer,
  containerClassName,
  nullable = false,
  singleIcon,
  ...props
}: SelectProps<T, K>) => (
  <SelectFactory
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    options={options}
    noSearch={noSearch}
    placement={placement}
    theme={props.theme}
    filterOptions={props.filterOptions}
    containerClassName={containerClassName}
    inputField={(inputProps) => <Input {...props} {...inputProps} />}
    itemRenderer={itemRenderer}
    notFoundRenderer={notFoundRenderer}
    // TODO lol
    nullable={nullable as false}
    singleIcon={singleIcon}
  />
);

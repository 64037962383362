import CircularProgress from '@material-ui/core/CircularProgress';
import { ComponentClass, FunctionComponent, lazy, LazyExoticComponent, Suspense } from 'react';
import { LoadableComponent } from 'react-loadable';
import { RouteComponentProps } from 'react-router-dom';
import { Any } from '~/common/utils';
import { routes } from '~/constants';

import { ENV } from '~/env';
import CapacityOverviewModule from '~/pages/Capacity-overview/Capacity-overview-module';
import AvellaLanding from '~/pages/corporate-landings/Avella';
import CBREModule from '~/pages/corporate-landings/CBRE';
import CVCModule from '~/pages/corporate-landings/CVC/CVCModule';
import DevoteamLanding from '~/pages/corporate-landings/Devoteam';
import FtiModule from '~/pages/corporate-landings/Fti-consulting/Fti-module';
import ISSModule from '~/pages/corporate-landings/ISS/ISSModule';
import KKWindSolutionsLanding from '~/pages/corporate-landings/KKWindSolutions';
import LlamasoftModule from '~/pages/corporate-landings/Llamasoft/Llamasoft-module';
import LonzaLanding from '~/pages/corporate-landings/Lonza';
import MackenzieModule from '~/pages/corporate-landings/Mackenzie/Mackenzie-module';
import ManusModule from '~/pages/corporate-landings/Manus/ManusModule';
import MerckLanding from '~/pages/corporate-landings/Merck';
import NielsenLanding from '~/pages/corporate-landings/Nielsen';
import NovoNordiskModule from '~/pages/corporate-landings/Novo-nordisk/Novo-nordisk-module';
import NovozymesLanding from '~/pages/corporate-landings/Novozymes';
import SikaModule from '~/pages/corporate-landings/Sika/Sika-module';
import SwissReLanding from '~/pages/corporate-landings/SwissRe';
import TabaccoLanding from '~/pages/corporate-landings/Tabacco';
import WestpharmaModule from '~/pages/corporate-landings/Westpharma/Westpharma-module';
import FeedbackModule from '~/pages/Feedback/Feedback-module';
import { LoginPage } from '~/pages/Login';
import NotFoundModule from '~/pages/Not-found/Not-found-module';
import { CustomFormRoutes } from '~/pages/Order-now/constants';
import OrderNowModule from '~/pages/Order-now/Order-now-module';
import { PasswordRoutes } from '~/pages/Reset-password';
import { SignupPage } from '~/pages/Sign-up';
import UnsubscribeModule from '~/pages/Unsubscribe/Unsubscribe-module';
import { LayoutProps } from './AppRoute';
import { DefaultLayout, InnerLayout, StaticLayout } from './layouts';

const UiKit = lazy(() => import('~/pages/ui-kit'));
const JoinTeam = lazy(() => import('~/pages/JoinTeam'));
const MyProfile = lazy(() => import('~/pages/My-profile'));

const PageLoader = () => (
  <div className="w-full h-screen flex justify-center items-center">
    <CircularProgress />
  </div>
);

const staffOnly = (role: string | null) => role === 'staff';
const customerOnly = (role: string | null) => role === 'customer';
const authorizedOnly = (role: string | null) => role !== null;
const alwaysVisible = () => true;

interface AppModule {
  url: typeof CustomFormRoutes | string;
  parameters: string;
  module:
    | LazyExoticComponent<(props: RouteComponentProps) => JSX.Element>
    | (() => JSX.Element)
    | (ComponentClass<Any, Any> & LoadableComponent)
    | (FunctionComponent<Any> & LoadableComponent);
  isExact: boolean;
  layout: {
    component: (props: LayoutProps) => JSX.Element;
    props: {
      isFooterShown?: boolean;
      isHeaderShown?: boolean;
    };
  };
  getVisibility: (role?: string | null) => boolean;
  redirectWhenLoggedIn: boolean;
}

const appRoutes = {
  modules: [
    {
      url: routes.join,
      parameters: '/:unique_access_token',
      module: () => (
        <Suspense fallback={<PageLoader />}>
          <JoinTeam />
        </Suspense>
      ),
      isExact: true,
      layout: {
        component: StaticLayout,
        props: {},
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: true,
    },
    {
      url: '/orders',
      parameters: '/:id/feedback',
      module: FeedbackModule,
      isExact: true,
      layout: {
        component: StaticLayout,
        props: {},
      },
      getVisibility: customerOnly,
      redirectWhenLoggedIn: false,
    },
    {
      url: CustomFormRoutes,
      parameters: '',
      module: OrderNowModule,
      isExact: false,
      layout: {
        component: InnerLayout,
        props: {},
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: false,
    },
    {
      url: routes.profile.index,
      parameters: '',
      module: () => (
        <Suspense fallback={<PageLoader />}>
          <MyProfile />
        </Suspense>
      ),
      isExact: false,
      layout: {
        component: InnerLayout,
        props: {},
      },
      getVisibility: customerOnly,
      redirectWhenLoggedIn: false,
    },
    {
      url: '/novonordisk',
      parameters: '',
      module: NovoNordiskModule,
      isExact: true,
      layout: {
        component: DefaultLayout,
        props: { isHeaderShown: false, isFooterShown: false },
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: false,
    },
    {
      url: '/swiss-re',
      parameters: '',
      module: SwissReLanding,
      isExact: true,
      layout: {
        component: DefaultLayout,
        props: { isHeaderShown: false, isFooterShown: false },
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: false,
    },
    {
      url: routes.landings.merck,
      parameters: '',
      module: MerckLanding,
      isExact: true,
      layout: {
        component: DefaultLayout,
        props: { isHeaderShown: false, isFooterShown: false },
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: false,
    },
    {
      url: routes.landings.nielsen,
      parameters: '',
      module: NielsenLanding,
      isExact: true,
      layout: {
        component: DefaultLayout,
        props: { isHeaderShown: false, isFooterShown: false },
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: false,
    },
    {
      url: routes.landings.lonza,
      parameters: '',
      module: LonzaLanding,
      isExact: true,
      layout: {
        component: DefaultLayout,
        props: { isHeaderShown: false, isFooterShown: false },
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: false,
    },
    {
      url: routes.landings.devoteam,
      parameters: '',
      module: DevoteamLanding,
      isExact: true,
      layout: {
        component: DefaultLayout,
        props: { isHeaderShown: false, isFooterShown: false },
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: false,
    },
    {
      url: routes.landings.kkwind,
      parameters: '',
      module: KKWindSolutionsLanding,
      isExact: true,
      layout: {
        component: DefaultLayout,
        props: { isHeaderShown: false, isFooterShown: false },
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: false,
    },
    {
      url: routes.landings.novozymes,
      parameters: '',
      module: NovozymesLanding,
      isExact: true,
      layout: {
        component: DefaultLayout,
        props: { isHeaderShown: false, isFooterShown: false },
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: false,
    },
    {
      url: routes.landings.optum,
      parameters: '',
      module: AvellaLanding,
      isExact: true,
      layout: {
        component: DefaultLayout,
        props: { isHeaderShown: false, isFooterShown: false },
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: false,
    },
    {
      url: routes.landings.tabacco,
      parameters: '',
      module: TabaccoLanding,
      isExact: true,
      layout: {
        component: DefaultLayout,
        props: { isHeaderShown: false, isFooterShown: false },
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: false,
    },
    {
      url: '/fti',
      parameters: '',
      module: FtiModule,
      isExact: true,
      layout: {
        component: DefaultLayout,
        props: { isHeaderShown: false, isFooterShown: false },
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: false,
    },
    {
      url: '/llamasoft',
      parameters: '',
      module: LlamasoftModule,
      isExact: true,
      layout: {
        component: DefaultLayout,
        props: { isHeaderShown: false, isFooterShown: false },
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: false,
    },
    {
      url: '/sika',
      parameters: '',
      module: SikaModule,
      isExact: true,
      layout: {
        component: DefaultLayout,
        props: { isHeaderShown: false, isFooterShown: false },
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: false,
    },
    {
      url: '/mackenzie',
      parameters: '',
      module: MackenzieModule,
      isExact: true,
      layout: {
        component: DefaultLayout,
        props: { isHeaderShown: false, isFooterShown: false },
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: false,
    },
    {
      url: '/westpharma',
      parameters: '',
      module: WestpharmaModule,
      isExact: true,
      layout: {
        component: DefaultLayout,
        props: { isHeaderShown: false, isFooterShown: false },
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: false,
    },
    {
      url: '/iss',
      parameters: '',
      module: ISSModule,
      isExact: true,
      layout: {
        component: DefaultLayout,
        props: { isHeaderShown: false, isFooterShown: false },
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: false,
    },
    {
      url: '/manus',
      parameters: '',
      module: ManusModule,
      isExact: true,
      layout: {
        component: DefaultLayout,
        props: { isHeaderShown: false, isFooterShown: false },
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: false,
    },
    {
      url: '/cvc',
      parameters: '',
      module: CVCModule,
      isExact: true,
      layout: {
        component: DefaultLayout,
        props: { isHeaderShown: false, isFooterShown: false },
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: false,
    },
    {
      url: '/cbre',
      parameters: '',
      module: CBREModule,
      isExact: true,
      layout: {
        component: DefaultLayout,
        props: { isHeaderShown: false, isFooterShown: false },
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: false,
    },
    // TODO: change url of capacity-overview after refactoring my-profile architecture
    {
      url: routes.profile.capacity_overview,
      parameters: '/:date?',
      module: CapacityOverviewModule,
      isExact: false,
      layout: {
        component: InnerLayout,
        props: {},
      },
      getVisibility: authorizedOnly,
      redirectWhenLoggedIn: false,
    },
    {
      url: `/staff-profile${routes.profile.capacity_overview}`,
      parameters: '/:email?',
      module: CapacityOverviewModule,
      isExact: false,
      layout: {
        component: InnerLayout,
        props: {},
      },
      getVisibility: staffOnly,
      redirectWhenLoggedIn: false,
    },
    {
      url: routes.signup,
      parameters: '',
      module: SignupPage,
      isExact: true,
      layout: {
        component: InnerLayout,
        props: {},
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: true,
    },
    {
      url: routes.login,
      parameters: '',
      module: LoginPage,
      isExact: true,
      layout: {
        component: InnerLayout,
        props: {},
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: true,
    },
    {
      url: routes.reset_password,
      parameters: '',
      module: PasswordRoutes,
      isExact: false,
      layout: {
        component: InnerLayout,
        props: {},
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: true,
    },
    {
      url: routes.unsubscribe,
      parameters: '/:type',
      module: UnsubscribeModule,
      isExact: true,
      layout: {
        component: InnerLayout,
        props: {},
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: false,
    },
    {
      url: '/crash',
      parameters: '',
      module: () => {
        throw new Error('CRASH');
      },
      isExact: true,
      layout: {
        component: StaticLayout,
        props: {},
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: false,
    },
    !ENV.PRODUCTION && {
      url: routes.ui_kit,
      parameters: '',
      module: () => (
        <Suspense fallback={<PageLoader />}>
          <UiKit />
        </Suspense>
      ),
      isExact: false,
      layout: {
        component: StaticLayout,
        props: {},
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: false,
    },
    {
      url: '*',
      parameters: '',
      module: NotFoundModule,
      isExact: false,
      layout: {
        component: StaticLayout,
        props: {},
      },
      getVisibility: alwaysVisible,
      redirectWhenLoggedIn: false,
    },
  ].filter(Boolean) as AppModule[],
  redirections: [
    {
      from: '/services',
      to: '/pricing',
    },
    {
      from: '/presentation-designers',
      to: '/presentation-services',
    },
    {
      from: '/presentation-design-services',
      to: '/presentation-services',
    },
    {
      from: '/presentation-experts',
      to: '/presentation-services',
    },
    {
      from: '/password/reset',
      to: routes.reset_password,
    },
    {
      from: '/password/reset/:token/:email',
      to: `${routes.reset_password}/:token/:email`,
    },
  ],
} as const;

export { appRoutes };

import { FormControlLabel, Checkbox } from '@material-ui/core';
import cx from 'classnames';
import { useCallback, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { Any } from '~/common/utils';
import { Modal } from '~/components/Modal';
import ModalParser from '~/components/Modal/ModalParser';
import { TextLink } from '~/components/ui/text-link';
import UploadFileBox from '~/components/ui/upload-file-box';
import { ComponentNames } from '~/pages/Order-now/constants';
import {
  orderFileUpload,
  orderFileUploadRemove,
  restoreOrderFiles,
} from '~/redux/modules/file-upload/file-upload.actions';
import { uploadedFilesSelector } from '~/redux/modules/file-upload/file-upload.selectors';
import {
  setUseSharingLinks,
  addUploadFile,
  removeUploadFile,
} from '~/redux/modules/order-now/data/data.actions';
import { isEmpty } from '~/utils/helpers';
import { FormStorageProps } from '~/utils/propTypes';

import './Upload-files.scss';

interface UploadFilesProps {
  properties?: {
    title?: string;
    subtitle?: string;
    suggestSharingLinks?: boolean;
    sharingLinksText?: string;
  };
  storage: FormStorageProps;
  fields: {
    name: 'useSharingLinks' | 'files';
    messages?: Any;
  }[];
}

export const UploadFiles = ({
  properties,
  storage: { errors, handleChange, handleError },
  fields,
}: UploadFilesProps) => {
  const { useSharingLinks, files: filesDetails } = useSelector(
    (state) => state.data.details.uploadFiles,
    shallowEqual,
  );
  const files = useSelector(uploadedFilesSelector, shallowEqual);
  const dispatch = useDispatch();

  const errorMessage = fields.find(({ name }) => name === 'files')?.messages?.requiredUnless;

  const filesAmount = filesDetails ? filesDetails.length : 0;

  useEffect(() => {
    if (isEmpty(files) && filesAmount) {
      dispatch(restoreOrderFiles(filesDetails));
      handleChange('files', filesAmount);
    }
  }, [dispatch, handleChange, filesAmount, filesDetails, files]);

  const checkboxToggleAction = useCallback(
    (event) => {
      const isChecked = event.target.checked;
      dispatch(setUseSharingLinks(isChecked));
      handleChange('useSharingLinks', isChecked);
    },
    [dispatch, handleChange],
  );

  const handleFileUpload = useCallback(
    (file) => {
      dispatch(orderFileUpload(file))
        .then(({ name, uuid }) => {
          dispatch(addUploadFile({ name, uuid }));
          handleChange('files', filesAmount + 1);
        })
        .catch(console.error);
    },
    [dispatch, handleChange, filesAmount],
  );

  const handleFileRemove = useCallback(
    (name) => {
      dispatch(orderFileUploadRemove(name));
      dispatch(removeUploadFile(name));
      handleChange('files', filesAmount - 1);
    },
    [dispatch, handleChange, filesAmount],
  );

  // Manual errors control
  useEffect(() => {
    if (useSharingLinks || filesAmount) {
      handleError('useSharingLinks');
      handleError('files');
    } else {
      handleError('files', errorMessage);
    }
  }, [handleError, useSharingLinks, filesAmount, errorMessage]);

  return (
    <div
      id={ComponentNames.UPLOAD_FILES}
      className={cx('upload-section', 'order-form__component', { 'handler-error': errors.files })}
    >
      {properties?.title && (
        <h2 className="upload-section__title order-form__title">{properties.title}</h2>
      )}
      {properties?.subtitle && (
        <div className="upload-section__subtitle order-form__subtitle">
          <Modal className="security-modal" modal="securityModal">
            <ModalParser source={properties.subtitle} />
          </Modal>
        </div>
      )}

      <div className="upload-section__filebox-wrapper">
        <UploadFileBox
          files={files}
          uploadFile={handleFileUpload}
          removeFile={handleFileRemove}
          hasError={!!errors.files}
        />
      </div>

      {properties?.suggestSharingLinks && (
        <div className="upload-section__google">
          <FormControlLabel
            className="google-control-label"
            control={<Checkbox onChange={checkboxToggleAction} checked={useSharingLinks} />}
            label={<span className="google-radio-label">{properties.sharingLinksText}</span>}
          />
          {useSharingLinks && (
            <div className="google-share-container">
              <div className="google-slides-hint">
                <span className="google-slides-hint__self">
                  Please make sure you share permissions. Simply click the blue SHARE button at the
                  top right of your presentation and share it with&nbsp;
                </span>
                <TextLink href="mailto:support@24slides.com">support@24slides.com</TextLink>
              </div>
              <div className="google-slides-hint">
                <Modal className="security-modal" modal="shareGoogleSlidesPreesentation">
                  <TextLink>Click here for an example</TextLink>
                </Modal>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

import { forwardRef } from 'react';
import { useLocation } from 'react-router-dom';
import { IconBox, Link } from '~/common/components';
import { cx } from '~/common/utils';
import { CustomerUser, useCurrentUser } from '../../Auth';
import { useNavigation } from '../context';
import { NavigationLink as NavigationLinkType } from '../types';

export const NavigationLink = forwardRef<HTMLAnchorElement, NavigationLinkType>(
  ({ icon, title, canShow, ...props }, ref) => {
    const { isFromHeader } = useNavigation();
    const location = useLocation();
    const user = useCurrentUser();
    if (canShow && !canShow(user as CustomerUser)) return null;

    const isActive = location.pathname.includes(props.to ?? props.href);

    return (
      <Link.Base
        ref={ref}
        {...props}
        className={cx(
          'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-300 focus-visible:rounded-sm',
          'my-profile-menu__item',
          { 'my-profile-menu__item--active': isActive },
          { 'my-profile-menu__item--user-menu': isFromHeader },
        )}
      >
        <div className={cx('menu-item-label', isActive && 'active')}>
          <IconBox
            className={cx('my-profile-menu-icon h-2 w-2', {
              'my-profile-menu-icon--user-menu': isFromHeader,
            })}
            icon={icon!}
          />
          <span
            className={cx('my-profile-menu-name', { 'my-profile-menu-name--active': isActive })}
          >
            {title}
          </span>
        </div>
      </Link.Base>
    );
  },
);

import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { ReactNode } from 'react';
import { IconBox, Tooltip } from '~/common/components';
import { cx } from '~/common/utils';
import css from './Input.module.scss';

export type FormElementLabelProps = {
  title?: string;
  hint?: ReactNode;
  required?: boolean;
  hintClickable?: boolean;
};

type Props = FormElementLabelProps & {
  disabled?: boolean;
};

export const FormElementLabel = ({ title, hint, required, hintClickable, disabled }: Props) => {
  if (!title && !hint) {
    return null;
  }

  return (
    <div
      className={cx(css.titleWrapper, disabled && css.disabled)}
      aria-label={typeof hint === 'string' ? hint : undefined}
    >
      {title && (
        <span className="truncate pointer-events-auto font-brand-b2">
          {title}
          {required && <span className="text-error-300"> *</span>}
        </span>
      )}
      {hint && (
        // TODO we should come up with standard padding for tooltips everywhere...
        <Tooltip
          content={hint}
          placement="right"
          className={css.hintTooltip}
          clickable={hintClickable}
        >
          <IconBox className={css.titleIcon} icon={faQuestionCircle} />
        </Tooltip>
      )}
    </div>
  );
};

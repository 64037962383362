import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { TESTING } from '~/env';

import css from './styles.module.scss';

type Props = {
  icon: IconProp;
  /**
   * @tip If you need non standard size, don't set size and use className instead
   */
  size?: 's' | 'm' | 'l';
  iconProps?: Omit<FontAwesomeIconProps, 'icon'>;
  className?: string;
};

const getProps = <T,>({ className, icon, iconProps, size, ...props }: Props & T) => ({
  ...props,
  className: cx(
    'flex flex-none items-center justify-center',
    size && css[`iconSize-${size}`],
    className,
  ),
  children: <FontAwesomeIcon {...iconProps} icon={icon} />,
});

type IconBoxProps = Props & ComponentPropsWithoutRef<'div'>;

//TODO create an icon size map from figma
export const IconBox = forwardRef<HTMLDivElement, IconBoxProps>((props, ref) => (
  <div ref={ref} {...getProps(props)} />
));

type IconButtonProps = Props & ComponentPropsWithoutRef<'button'>;

// TODO get rid of this component
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  const { className, ...buttonProps } = getProps(props);
  return (
    <button
      {...buttonProps}
      // apparently firefox only ignores onClick, while chrome ignores every
      // event, breaking tooltips on disabled elements
      disabled={TESTING ? props.disabled : false}
      onClick={props.disabled ? undefined : props.onClick}
      ref={ref}
      type={buttonProps.type || 'button'}
      className={cx(className, 'focus-visible:ring')}
    />
  );
});

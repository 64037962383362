export const defaultMimeTypes = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/gif',
  'image/svg+xml',
  'image/bmp',
  'image/webp',
  'image/vnd.adobe.photoshop',
  'video/mp4',
  'application/postscript',
  'font/otf',
  'font/ttf',
  'font/woff',
  'font/woff2',
  'text/plain',
  'application/vnd.ms-excel',
  'application/msexcel',
  'application/x-msexcel',
  'application/x-ms-excel',
  'application/x-excel',
  'application/x-dos_ms_excel',
  'application/xls',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-powerpoint [official]',
  'application/vnd.ms-powerpoint.template.macroEnabled.12',
  'application/mspowerpoint',
  'application/ms-powerpoint',
  'application/mspowerpnt',
  'application/vnd-mspowerpoint',
  'application/powerpoint',
  'application/x-powerpoint',
  'application/x-m',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.template',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/msword',
  'application/pdf',
  'application/zip',
  'application/x-zip-compressed',
  'application/x-rar-compressed',
  'application/x-7z-compressed',
  'application/vnd.ms-word.document.macroenabled.12',
  'application/vnd.ms-word.template.macroenabled.12',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/x-iwork-keynote-sffkey',
  'application/x-iwork-pages-sffpages',
  'application/x-iwork-numbers-sffnumbers',
  'application/x-photoshop',
  'application/photoshop',
  'application/psd',
  'application/vnd.apple.keynote',
  'application/vnd.apple.pages',
  'application/vnd.apple.numbers',
  'application/octet-stream',
  'multipart/x-zip',
];

import { ReactNode, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Scroll from 'react-scroll';

import { Header } from '~/components/partials/Header';
import Footer from '~/components/ui/Footer';
import { getCanonicalHref } from '~/utils/canonicalHref';

export const StaticLayout = ({ children }: { children: ReactNode }) => {
  const location = useLocation();

  useEffect(() => {
    Scroll.animateScroll.scrollToTop({
      duration: 0,
    });
  }, [location.pathname]);

  const canonicalHref = getCanonicalHref();

  return (
    <div className="flex flex-col h-screen">
      <Helmet>
        <link rel="canonical" href={canonicalHref} />
      </Helmet>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

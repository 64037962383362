import {
  arrow,
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingNode,
  FloatingPortal,
  offset,
  Placement,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useInteractions,
} from '@floating-ui/react';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useRef,
  useState,
} from 'react';
import { CSSTransition } from 'react-transition-group';
import { MAX_TAB_INDEX } from '~/common/hooks';
import { Any, cx, getCSSTransitionClassNames } from '~/common/utils';
import { FloatingDiv, useArrow, WithFloatingTree } from './Floating';
import { ModalContent } from './Modal';
import { styles } from './Tooltip';

export type TriggerProps = {
  ref: (node: HTMLElement | null) => void;
  onClick: (event: SyntheticEvent) => void;
  onKeyDown: (event: SyntheticEvent) => void;
  onKeyUp: () => void;
  onPointerDown: (event: SyntheticEvent) => void;
};

type CommonProps = {
  color?: 'white' | 'grey' | 'lightGrey' | 'danger';
  className?: string;
  placement?: Placement;
  fallbackPlacements?: Placement[];
  compensateOffset?: number;
  noArrow?: boolean;
  noDismiss?: boolean;
  onClose?: () => void;
  onClick?: () => void;
  externalState?: [
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>> | ((value: boolean) => void),
  ];
  openOnMount?: boolean;
  matchTriggerWidth?: boolean;
};

type PropsWithForwardOpened = CommonProps & {
  forwardOpened: true;
  trigger: (props: TriggerProps & { open: boolean }) => ReactNode;
  content: ModalContent;
};

type PropsWithoutForwardOpened = CommonProps & {
  forwardOpened?: false;
  trigger: (props: TriggerProps) => ReactNode;
  content: ModalContent;
};

type Props = PropsWithForwardOpened | PropsWithoutForwardOpened;

export const Popover = WithFloatingTree(
  ({
    trigger,
    color = 'white',
    className,
    placement = 'bottom',
    fallbackPlacements,
    compensateOffset = 0,
    noArrow = false,
    noDismiss = false,
    onClose,
    onClick,
    externalState,
    forwardOpened,
    openOnMount = false,
    matchTriggerWidth = false,
    content,
  }: Props) => {
    const internalState = useState(openOnMount);
    const [open, setOpen] = externalState ?? internalState;
    const close = useCallback(() => setOpen(false), [setOpen]);

    const arrowRef = useRef<HTMLDivElement>(null);

    const nodeId = useFloatingNodeId();

    const {
      floatingStyles,
      refs,
      context,
      middlewareData,
      placement: floatingPlacement,
    } = useFloating({
      nodeId,
      placement,
      open,
      onOpenChange: setOpen,
      middleware: [
        offset({
          mainAxis: 12 - compensateOffset,
        }),
        flip({ fallbackPlacements }),
        shift({ padding: 8 }),
        arrow({ element: arrowRef }),
        size({
          apply({ elements }) {
            if (matchTriggerWidth) {
              Object.assign(refs.floating.current?.style ?? {}, {
                width: `${elements.reference.getBoundingClientRect().width}px`,
                maxWidth: `${elements.reference.getBoundingClientRect().width}px`,
              });
            }
          },
        }),
      ],
      whileElementsMounted: autoUpdate,
    });

    const { arrowProps, staticSide } = useArrow(arrowRef, floatingPlacement);

    const { getReferenceProps, getFloatingProps } = useInteractions([
      useClick(context),
      useDismiss(context, {
        ancestorScroll: true,
        enabled: !noDismiss,
        // TODO extend this once we know what other extensions our users want to use
        outsidePress: (event) => {
          const target = event.target as Element;
          return (
            !target.shadowRoot &&
            !target.closest('.hot-toast') &&
            !target.closest('.MuiDialogContent-root')
          );
        },
      }),
    ]);

    return (
      <FloatingNode id={nodeId}>
        <FloatingPortal>
          <CSSTransition
            classNames={getCSSTransitionClassNames(styles)}
            in={open}
            timeout={300}
            unmountOnExit
            onExited={onClose}
            nodeRef={refs.floating}
          >
            <FloatingFocusManager context={context} initialFocus={MAX_TAB_INDEX}>
              <FloatingDiv
                {...getFloatingProps({
                  ref: refs.setFloating,
                  className: cx(
                    'pointer-events-auto',
                    styles.tooltip,
                    styles[color],
                    styles[staticSide],
                    className,
                  ),
                  style: floatingStyles,
                  onClick,
                })}
              >
                {!noArrow && <div {...arrowProps(middlewareData)} className={styles.arrow} />}
                {content({ onClose: close })}
              </FloatingDiv>
            </FloatingFocusManager>
          </CSSTransition>
        </FloatingPortal>
        {trigger(
          getReferenceProps(
            forwardOpened ? { ref: refs.setReference, open } : { ref: refs.setReference },
          ) as Any,
        )}
      </FloatingNode>
    );
  },
);

import { useMutation } from '@tanstack/react-query';
import { Toast } from '~/common/components';
import { useIdParam } from '~/common/hooks';
import { axios } from '~/root';
import { axiosErrorMessageSchema } from '~/root/domain';
import { Permission, membersSchema } from '../domain';
import { useUpdateOrderCache } from './useOrder';

type MemberPayload = {
  email: string;
  permission: Permission;
};

export const useUpdateMembers = () => {
  const id = useIdParam();
  const { setQuery } = useUpdateOrderCache();

  return useMutation({
    mutationFn: (members: MemberPayload[]) => {
      return axios
        .put(`/v1/orders/${id}/members`, { invitations: members })
        .then((res) => membersSchema.parse(res.data.members));
    },
    // replace them with fresh ones from BE
    onSuccess: (members) => {
      setQuery((prev) => {
        const next = { ...prev, members };
        if (next.isConfidential && next.members.creator?.id !== next.members.owner?.id) {
          const owner = next.members.members.find((member) => member.id === next.members.owner?.id);
          if (owner) {
            owner.editable = true;
          }
        }
        return next;
      });
      Toast.success({ message: 'Members were successfully updated.' });
    },
    onError: (error) => {
      const result = axiosErrorMessageSchema.safeParse(error);
      if (result.success) {
        Toast.error({ message: result.data });
      }
    },
  });
};

// TODO add notification for success
export const useRemoveMembers = () => {
  const id = useIdParam();
  const { cancelQuery, getQuery, setQuery } = useUpdateOrderCache();

  return useMutation({
    mutationFn: (members: string[]) => {
      return axios.delete(`/v1/orders/${id}/members`, {
        data: {
          members,
        },
      });
    },
    onMutate: async (members) => {
      await cancelQuery();
      const prev = getQuery();
      setQuery((prev) => ({
        ...prev,
        members: {
          ...prev.members,
          invited: prev.members.invited.filter((member) => !members.includes(member.email)),
          admins: prev.members.admins.filter((member) => !members.includes(member.email)),
          members: prev.members.members.filter((member) => !members.includes(member.email)),
        },
      }));
      return prev;
    },
    onSuccess: () =>
      Toast.warning({ message: 'Members were successfully removed from the order.' }),
    // rollback
    onError: (_error, _variables, prev) => setQuery(prev),
  });
};

// TODO add notification for success
export const useResendInvitation = () => {
  return useMutation({
    mutationFn: (ids: number[]) => axios.put('/v1/customers/invitations', { ids }),
  });
};

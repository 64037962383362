import { useForm } from 'formoid';
import { FormEvent } from 'react';
import { CheckboxField, InputField, PasswordField, Button, Link } from '~/common/components';
import { customValidator, propagateBackendValidationErrors } from '~/common/utils';
import { OauthProviders } from '~/components/OauthProviders';
import { routes } from '~/constants';
import { useLogin } from '~/root/Auth';

const initialValues = {
  email: '',
  password: '',
  remember: false,
};

export const LoginForm = ({ onClose, className }: { onClose?: () => void; className?: string }) => {
  const login = useLogin();

  const { fieldProps, handleSubmit, setErrors, errors } = useForm({
    initialValues,
    validationStrategy: 'onBlur',
    validators: () => ({
      password: null,
      email: customValidator.email(),
      remember: null,
    }),
  });

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit((values) => {
      return login
        .mutateAsync(values)
        .then(onClose)
        .catch((error: unknown) => {
          propagateBackendValidationErrors({ error, setErrors, formErrors: errors });
        });
    });
  };

  return (
    <div className={className}>
      <h2 className="font-brand-t2b mb-1">Log in</h2>
      <p className="font-brand-b2 mb-3 text-text-400">
        Don’t have an account?&nbsp;
        <Link to={routes.signup}>Sign up</Link>
      </p>

      <OauthProviders className="mb-3" />

      <form className="space-y-3" onSubmit={submit}>
        <InputField {...fieldProps('email')} type="email" placeholder="Email" />

        <PasswordField {...fieldProps('password')} placeholder="Password" />

        <Link to={routes.reset_password}>Forgot password?</Link>

        <CheckboxField {...fieldProps('remember')} title="Remember me" />

        <Button
          className="w-full"
          color="secondary"
          size="m"
          loading={login.isLoading}
          type="submit"
        >
          Continue
        </Button>
      </form>
    </div>
  );
};

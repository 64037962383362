import styles from './SubscriptionPlansVideo.module.scss';

export const SubscriptionPlansVideo = () => {
  return (
    <iframe
      allowFullScreen
      title="Our Subscription Plans Explained"
      className={styles.explainedIframe}
      src="https://player.vimeo.com/video/497942868?color=147abf&title=0&byline=0&portrait=0&autoplay=1"
      allow="autoplay; fullscreen"
    />
  );
};

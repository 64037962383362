import { useForm } from 'formoid';
import { useMemo, useRef } from 'react';
import {
  Collapse,
  FormElementLabel,
  InputField,
  ModalContentProps,
  ModalHeader,
  ModalShell,
  SelectField,
  Switcher,
} from '~/common/components';
import { formatMoney, record, customValidator } from '~/common/utils';
import {
  useBillingData,
  useBillingEditTopUp,
  useBillingRemoveTopUp,
  useCreditPackagesData,
} from '../../hooks';

const DEFAULT_AUTO_TOPUP_TRESHOLD = 250;

export const EditAutoTopupModal = ({ onClose }: ModalContentProps) => {
  const { topUp, defaultPaymentMethod } = useBillingData();
  const creditPackages = useCreditPackagesData();

  const initialValues = useRef({
    enableTopUp: !!topUp,
    auto_topup_threshold: topUp?.topUpCreditBelow || DEFAULT_AUTO_TOPUP_TRESHOLD,
    packageId: topUp?.id || null,
  });

  const editTopupMutation = useBillingEditTopUp();
  const deleteTopupMutation = useBillingRemoveTopUp();

  const form = useForm({
    initialValues: initialValues.current,
    validators: (values) => ({
      ...getAutoTopupFieldsValidation(values),
      packageId: customValidator.required<number | null>(),
    }),
    validationStrategy: 'onBlur',
  });

  const submit = () =>
    form.handleSubmit(({ enableTopUp, auto_topup_threshold, packageId }) =>
      enableTopUp
        ? editTopupMutation
            .mutateAsync({
              paymentMethod: defaultPaymentMethod?.id as number,
              package: packageId,
              creditsBelow: auto_topup_threshold,
            })
            .then(onClose)
        : deleteTopupMutation.mutateAsync().then(onClose),
    );

  const selectOptions = useMemo(
    () => creditPackages.map(({ id, name }) => ({ value: id, name })),
    [creditPackages],
  );

  const packagePrice = useMemo(
    () =>
      creditPackages.find((creditPackage) => creditPackage.id === form.values.packageId)?.amount ||
      0,
    [creditPackages, form.values.packageId],
  );

  const isDirty = !record.shallowEqual(form.values, initialValues.current);

  return (
    <>
      <ModalHeader title="Edit automatic top-up" onClose={onClose} />
      <ModalShell
        submitText="Save changes"
        onClose={onClose}
        onSubmit={submit}
        loading={editTopupMutation.isLoading || deleteTopupMutation.isLoading}
        disabled={!isDirty}
        className="w-[343px] md:w-[616px]"
      >
        <Switcher title="Automatically top-up my credits" {...form.fieldProps('enableTopUp')} />
        <Collapse in={form.values.enableTopUp} className="mt-3">
          <div className="flex gap-2 sm:flex-col mb-3">
            <SelectField
              {...form.fieldProps('packageId')}
              className="max-w-[268px]"
              title="Package type"
              options={selectOptions}
            />
            <div className="inline-flex flex-col">
              <FormElementLabel title="Price" />
              <span className="px-1 py-[13px] font-brand-b2">{formatMoney(packagePrice)}</span>
            </div>
          </div>
          <InputField
            type="number"
            title="When credit balance falls below"
            placeholder="Enter number of credits"
            className="max-w-[268px]"
            {...form.fieldProps('auto_topup_threshold')}
          />
        </Collapse>
      </ModalShell>
    </>
  );
};

export const getAutoTopupFieldsValidation = ({ enableTopUp }: { enableTopUp: boolean }) => ({
  enableTopUp: null,
  auto_topup_threshold: enableTopUp ? customValidator.positiveInteger() : null,
});

import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import ReactPlayer from 'react-player';
import { Button } from '~/common/components';
import tableImg from './images/prices-table.png';
import './_Modal-treatments-type.scss';

export const ModalTreatmentsType = ({ onClose }: { onClose: () => void }) => (
  <div className="treatments-modal">
    <div className="treatments-modal__container">
      <span className="treatments-modal__container__title">{modalContent.title}</span>
      <div className="treatments-modal__container__examples">
        {modalContent.treatments.map((example) => (
          <div className="treatments-example" key={example.id}>
            <div className="treatments-example__header">
              <span className="treatments-example__header__title">{example.name}</span>
              <span className="treatments-example__header__perfect">
                {modalContent.perfectTreatment}
              </span>
            </div>
            <ul className="treatments-example__list">
              {example.list.map((item, num) => (
                <li className="treatments-li" key={`list${num + 1}`}>
                  <div>
                    <CheckCircleIcon color="#46b688" />
                  </div>
                  <span className="treatments-li__text">{item}</span>
                </li>
              ))}
            </ul>
            <div className="treatments-example__wrapper">
              <ReactPlayer
                url={example.videoUrl}
                className="treatments-example__wrapper__video"
                controls
                width="100%"
                height="100%"
                config={{
                  vimeo: {
                    autoplay: true,
                    quality: '1080p',
                  },
                }}
              />
            </div>
          </div>
        ))}
      </div>
      <span className="treatments-modal__container__description">
        {modalContent.bottomDescription}
      </span>
    </div>
    <img src={tableImg} alt="" className="treatments-modal__table-img" />
    <div className="treatments-modal__close-button">
      <Button size="m" color="primary-outlined" onClick={onClose}>
        {modalContent.closeButton}
      </Button>
    </div>
  </div>
);

const modalContent = {
  title: 'Our Treatment Types Explained',
  bottomDescription:
    'If you’d like a mix of treatments for your presentation, simply let us know in your brief. If you’re not sure, your project manager will go through your presentation and recommend which treatment we use for each slide. If this will increase the price of your order, we will always let you know before we start work.',
  closeButton: 'close',
  perfectTreatment: 'This treatment is perfect for you if…',
  treatments: [
    {
      id: '1',
      name: 'Fix up',
      videoUrl: 'https://player.vimeo.com/video/297906469',
      list: [
        'Your content is already in PowerPoint and you want it to match a template, CVI, your company’s brand guidelines or simply want us to tidy up your slides.',
        'We’ll make sure the colors and fonts complement your presentation. Plus we’ll tidy up the spacing and alignment so every slide looks consistent and professional.',
      ],
    },
    {
      id: '2',
      name: 'Redesign',
      videoUrl: 'https://player.vimeo.com/video/297906491',
      list: [
        'You want to significantly change the look and feel of your current presentation.',
        'You want custom icons or illustrations to amplify your message and create a presentation that is impactful and unique.',
        'You can send us your content in PowerPoint or even in a word document and we’ll create the slides for you.',
      ],
    },
    {
      id: '3',
      name: 'Redraw',
      videoUrl: 'https://player.vimeo.com/video/297906521',
      list: [
        'You have a photo of a whiteboard, sketch or even scribbles on a paper napkin that you would like us to illustrate and convert into a fully editable PowerPoint slide.',
        'You have non-editable images, graphs or charts in your presentation that you would like us to recreate so you can edit them within PowerPoint.',
      ],
    },
  ],
};

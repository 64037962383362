import { useLocation } from 'react-router-dom';
import { createContextPair } from '~/common/kits/context';
import { config } from './config';
import { NavigationProps } from './types';

const useContextData = ({ isFromHeader = false }: NavigationProps) => {
  const location = useLocation();
  const innerOrderRegExp = /orders\/?(.*)/;
  const isMenuCollapsed = !!location.pathname.match(innerOrderRegExp)?.[1];

  return {
    config,
    isFromHeader,
    isMenuCollapsed,
  };
};

const [useNavigation, withNavigation] = createContextPair(useContextData);

export { useNavigation, withNavigation };

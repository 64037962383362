import { useIsMutating } from '@tanstack/react-query';
import { useForm } from 'formoid';
import { FormEvent } from 'react';
import {
  CheckboxField,
  InputField,
  PasswordField,
  PasswordTooltipContent,
  Tooltip,
  Button,
  Link,
} from '~/common/components';
import {
  PASSWORD_MIN_LENGTH,
  customValidator,
  propagateBackendValidationErrors,
} from '~/common/utils';
import { routes } from '~/constants';
import { qk } from '~/root';
import { useSignup } from '~/root/Auth';
import { OauthProviders } from '../OauthProviders';

const initialValues = {
  fullName: '',
  email: '',
  password: '',
  receiveOffers: false,
};

export const SignupForm = () => {
  const { mutateAsync: signup, isLoading } = useSignup();
  const isOauthMutating = useIsMutating({ mutationKey: [qk.oauthLogin] }) > 0;

  const { fieldProps, handleSubmit, setErrors, errors } = useForm({
    initialValues,
    validationStrategy: 'onBlur',
    validators: () => ({
      fullName: customValidator.onlyLatinLetters(),
      password: customValidator.password(),
      email: customValidator.email(),
      receiveOffers: null,
    }),
  });

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit((values) => {
      return signup(values).catch((error: unknown) => {
        propagateBackendValidationErrors({ error, setErrors, formErrors: errors });
      });
    });
  };

  const signupLoading = isLoading || isOauthMutating;

  return (
    <>
      <h2 className="font-brand-t2b mb-1">Sign up</h2>
      <p className="font-brand-b2 mb-3 text-text-400">
        Already have an account?&nbsp;
        <Link to={routes.login}>Log in</Link>
      </p>

      <OauthProviders className="mb-3" />

      <form className="space-y-3" onSubmit={submit}>
        <InputField
          {...fieldProps('fullName')}
          type="text"
          title="Full name"
          placeholder="Enter your full name"
        />

        <InputField
          {...fieldProps('email')}
          type="email"
          title="Email"
          placeholder="Enter your email"
        />

        <Tooltip content={<PasswordTooltipContent />} placement="right">
          <PasswordField
            {...fieldProps('password')}
            title="Password"
            placeholder={`At least ${PASSWORD_MIN_LENGTH} characters`}
          />
        </Tooltip>

        <CheckboxField
          {...fieldProps('receiveOffers')}
          title="I'd like to receive occasional emails with information on new services and offers"
        />

        {/* TODO: Check is it needed here to have OAuth loading state */}
        <Button className="w-full" size="m" color="secondary" loading={signupLoading} type="submit">
          Continue
        </Button>
      </form>

      <p className="terms-text text-justify font-brand-b2 mt-2">
        By signing up, you agree to our&nbsp;
        <a
          className="inline-block text-primary-300"
          href={routes.public.documents.terms_and_conditions}
        >
          Terms of Use
        </a>
        ,
        <a className="inline-block text-primary-300" href={routes.public.documents.privacy_policy}>
          Privacy Policy
        </a>
        &nbsp;and&nbsp;
        <a
          target="_blank"
          className="inline-block text-primary-300"
          href={routes.public.documents.data_processing_agreement}
          rel="noreferrer"
        >
          Data Processing Agreement
        </a>
      </p>
    </>
  );
};

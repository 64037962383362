import { ReactNode } from 'react';
import { cardBrandToImg } from '~/assets/images/card-brands';
import { Menu, useModalContext } from '~/common/components';
import { cx } from '~/common/utils';
import { CardPaymentMethod } from '~/root/domain';
import { useBillingData, useSetDefaultPaymentMethodSubmit } from '../hooks';
import { DeletePaymentMethodModal } from './DeletePaymentMethodModal';

type CreditCardPaymentMethodProps = CardPaymentMethod & {
  className?: string;
  actions?: ReactNode;
};

export const CreditCardPaymentMethod = ({
  className,
  actions,
  ...paymentMethod
}: CreditCardPaymentMethodProps) => (
  <div
    className={cx(
      className,
      'flex w-full gap-1 px-2 py-[12px] border border-other-500 border-solid rounded bg-other-50',
    )}
  >
    <CreditCardPaymentMethodInfo {...paymentMethod} />
    {actions}
  </div>
);

const CreditCardPaymentMethodInfo = (props: CardPaymentMethod) => (
  <div className="flex w-full justify-between items-center sm:items-start">
    <div className="flex sm:flex-col gap-x-2 gap-y-[4px] items-center sm:items-start font-brand-b2 text-text-500">
      <img src={cardBrandToImg(props.brand)} alt={props.brand} className="h-3" />
      <div className="flex sm:flex-col gap-x-1">
        <span>Ends with {props.last4}</span>
        <span className="text-text-400">
          Expires {props.expirationMonth}/{props.expirationYear}
        </span>
      </div>
    </div>

    {props.default && (
      <span className="font-brand-sm h-3 px-1 inline-flex items-center bg-other-200 rounded-sm">
        Default
      </span>
    )}
  </div>
);

export const CreditCardPaymentMethodActions = (props: CardPaymentMethod) => {
  const { modalOpener } = useModalContext();
  const { paymentMethods } = useBillingData();

  const setDefaultPaymentMethod = useSetDefaultPaymentMethodSubmit();

  const deleteOptionVisible = paymentMethods.length > 1;
  const setDefaultOptionVisible = !props.default;

  const isMenuVisible = deleteOptionVisible || setDefaultOptionVisible;

  if (!isMenuVisible) {
    return null;
  }

  return (
    <Menu>
      {setDefaultOptionVisible && (
        <Menu.Item onClick={() => setDefaultPaymentMethod(props.id)}>Set as default</Menu.Item>
      )}
      {deleteOptionVisible && (
        <Menu.Item
          className="text-error-300"
          onClick={modalOpener(DeletePaymentMethodModal, props)}
        >
          Delete
        </Menu.Item>
      )}
    </Menu>
  );
};

import { z } from 'zod';

export const dateFromUnixEpochSchema = z.number().transform((value) => new Date(value * 1000));

export const countrySchema = z.object({
  id: z.number(),
  name: z.string(),
  countryCode: z.string().optional(),
  dialCode: z.number().optional(),
});

/**
 * @deprecated We should start unify such things everywhere on CA the same way as it is on staff
 */
export const oldCountrySchema = z
  .object({
    id: z.number(),
    name: z.string(),
    country_code: z.string().optional(),
    dial_code: z.number().optional(),
  })
  .transform(
    ({ id, name, country_code, dial_code }) =>
      ({
        id,
        name,
        dialCode: dial_code,
        countryCode: country_code,
        // It's done to let typescript know that transform gives you the same data as in countrySchema
      }) as { id: number; name: string; countryCode?: string; dialCode?: number },
  );

export type Country = z.infer<typeof countrySchema>;

export const customerTypeSchema = z.enum([
  '',
  'new',
  'returning',
  'retainer',
  'contract',
  'custom portal',
  'subscription',
]);

export type CustomerType = z.infer<typeof customerTypeSchema>;

export const teamRoleSchema = z.enum(['manager', 'admin', 'owner']);

export type TeamRole = z.infer<typeof teamRoleSchema>;

export const treatmentSchema = z.enum(['fixup', 'other', 'freehand', 'redraw', 'redesign']);

export type Treatment = z.infer<typeof treatmentSchema>;

export type ProductType = 'CreditPackage' | 'Order' | 'AccountSubscription';

export const axiosErrorMessageSchema = z
  .object({
    data: z.object({
      data: z.object({
        message: z.string(),
      }),
    }),
  })
  .transform((error) => error.data.data.message);

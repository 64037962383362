import { faFacebook, faLinkedin, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef } from 'react';
import { HELP, routes, socials } from '~/constants';

import '~/styles/components/_footer.scss';

const Footer = () => {
  const year = useRef(new Date().getFullYear());

  return (
    <div className="footer footer__wrapper">
      <div className="footer__container">
        <div className="footer__nav-container f-wrap f-justify-between">
          {footerLinksC.map(({ title, links }) => (
            <div key={title} className="footer__nav">
              <h3 className="footer__title">{title}</h3>
              <div>
                {links.map((link) => (
                  <a
                    key={link.label}
                    href={link.to}
                    target={link.external ? '_blank' : undefined}
                    className="footer__link f"
                    rel="noreferrer"
                  >
                    {'icon' in link && (
                      <div className="footer__icon boundary-box">
                        <FontAwesomeIcon icon={link.icon} />
                      </div>
                    )}
                    {link.label}
                  </a>
                ))}
              </div>
            </div>
          ))}
        </div>
        <p className="footer__copyrights text-center">{year.current} &copy; 24Slides</p>
      </div>
    </div>
  );
};

const footerLinksC = [
  {
    title: 'Services',
    links: [
      { label: 'Dedicated teams', to: routes.public.dedicated_team, external: false },
      { label: 'Enterprise solution', to: routes.public.enterprises, external: false },
      { label: 'Presentation services', to: routes.public.services, external: false },
      { label: 'Present Better', to: routes.public.present_better, external: false },
      { label: 'Free templates', to: routes.public.templates, external: false },
    ],
  },
  {
    title: 'Treatments',
    links: [
      { label: 'Fix up', to: routes.public.treatments.fixup, external: false },
      { label: 'Redesign', to: routes.public.treatments.redesign, external: false },
    ],
  },
  {
    title: 'Pricing',
    links: [
      { label: 'Slide prices', to: routes.public.pricing.slides, external: false },
      { label: 'Credit packages', to: routes.public.pricing.credits, external: false },
      { label: 'Dedicated teams', to: routes.public.dedicated_team, external: false },
    ],
  },
  {
    title: 'Company',
    links: [
      { label: 'Our purpose', to: routes.public.about.purpose, external: false },
      { label: 'Our story', to: routes.public.about.index, external: false },
      { label: 'Our team', to: routes.public.about.team, external: false },
      { label: 'Reviews', to: routes.public.reviews.ratings, external: false },
      { label: '24Slides blog', to: routes.public.blog, external: false },
      { label: 'Contact us', to: routes.public.contact_us, external: false },
    ],
  },
  {
    title: 'More',
    links: [
      { label: 'Help center', to: HELP, external: true },
      {
        label: 'Terms and conditions',
        to: routes.public.documents.terms_and_conditions,
        external: false,
      },
      { label: 'Security policy', to: routes.public.security, external: false },
      { label: 'Cookie policy', to: routes.public.documents.cookies_policy, external: false },
      { label: 'Privacy policy', to: routes.public.documents.privacy_policy, external: false },
      {
        label: 'Data Processing Agreement',
        to: routes.public.documents.data_processing_agreement,
        external: true,
      },
    ],
  },
  {
    title: 'Follow us',
    links: [
      { label: 'Facebook', to: socials.facebook, external: true, icon: faFacebook },
      { label: 'Instagram', to: socials.instagram, external: true, icon: faInstagram },
      { label: 'LinkedIn', to: socials.linkedin, external: true, icon: faLinkedin },
      { label: 'X', to: socials.x, external: true, icon: faTwitter },
    ],
  },
] as const;

export default Footer;

import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FieldProps, useForm, validator } from 'formoid';
import { ChangeEventHandler, useMemo } from 'react';
import {
  CheckboxField,
  ErrorModal,
  Errors,
  IconBox,
  ModalContentProps,
  ModalHeader,
  ModalShell,
  useModalContext,
} from '~/common/components';
import { Any, cx, formatMoney, customValidator } from '~/common/utils';
import { useBillingData, useRenewTopupSubscription } from '../../../hooks';
import { UnpaidOrders } from './UnpaidOrders';

type ManualTopupProps = ModalContentProps;

type FormValues = {
  period: number | null;
  topUp: boolean;
};

export const ManualTopup = ({ onClose }: ManualTopupProps) => {
  const { modalOpener } = useModalContext();
  const { subscription } = useBillingData();

  const retainerProductMonthlyPrice = subscription?.products.retainer?.amount;

  const minimalTopupPeriod = useMemo(() => {
    const unpaidOrdersPrice = subscription?.unpaidOrders?.reduce(
      (sum, { price }) => sum + price,
      0,
    );

    if (!unpaidOrdersPrice) {
      return 1;
    }

    return retainerProductMonthlyPrice
      ? Math.ceil(unpaidOrdersPrice / retainerProductMonthlyPrice)
      : 1;
  }, [retainerProductMonthlyPrice, subscription?.unpaidOrders]);

  const { fieldProps, handleSubmit, values } = useForm({
    initialValues: { period: minimalTopupPeriod, topUp: !!subscription?.topUp } as FormValues,
    validationStrategy: 'onSubmit',
    validators: () => ({
      period: validator.sequence(
        customValidator.required<number | null>('Enter period'),
        validator.min(minimalTopupPeriod, `Should be > ${minimalTopupPeriod - 1}`),
      ),
      topUp: null,
    }),
  });

  const topupSubscriptionMutation = useRenewTopupSubscription();

  if (!subscription) {
    return null;
  }

  const submit = () =>
    handleSubmit((values) =>
      topupSubscriptionMutation
        .mutateAsync({ data: values, id: subscription.id })
        .then(onClose)
        .catch(() => {
          modalOpener(ErrorModal, {
            onClose,
          })();
        }),
    );

  return (
    <>
      <ModalHeader onClose={onClose} title="Manual top-up" />
      <ModalShell
        submitText="Top up subscription"
        onClose={onClose}
        onSubmit={submit}
        loading={topupSubscriptionMutation.isLoading}
        className="md:w-[616px]"
        disabled={values.period === null || values.period <= 0}
      >
        {!!subscription.unpaidOrders?.length && (
          <UnpaidOrders unpaidOrders={subscription.unpaidOrders} className="mb-4" />
        )}
        <div className="flex flex-wrap items-center gap-x-3 gap-y-2 w-full rounded px-2 py-1 bg-other-100">
          <span className="font-brand-b1 text-text-500">Top-up amount:</span>
          <QuantityField
            {...fieldProps('period')}
            className="sm:order-3 sm:basis-full sm:justify-center"
          />
          <span className="font-brand-t2m text-text-500">
            {formatMoney(
              retainerProductMonthlyPrice! *
                (values.period !== null && values.period > 0 ? values.period : 0),
            )}
          </span>
        </div>
        <p className="font-brand-b2 text-text-400 mt-2">
          You'll get extra credits added to your account along with your usual subscription fee.
          These credits won't expire and will be there until you decide to use them.
        </p>
        <CheckboxField
          title="Auto top up every time there is not enough credits for order processing"
          className="mt-2"
          {...fieldProps('topUp')}
        />
      </ModalShell>
    </>
  );
};

const buttonClassNames =
  'flex items-center justify-center shrink-0 text-white rounded-full w-3 h-3';

type QuantityFieldProps = FieldProps<number | null> &
  Partial<{
    placeholder: string;
    className: string;
  }>;

// TODO: extract as separate UI component
const QuantityField = ({
  errors,
  value,
  onChange,
  onBlur,
  className,
  ...props
}: QuantityFieldProps) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.currentTarget.valueAsNumber;

    return onChange(Number.isNaN(value) ? (null as Any) : value);
  };

  const handleButtonClick = (type: 'increment' | 'decrement') => {
    const newValue =
      type === 'increment' ? (value || 0) + 1 : value !== null && value >= 1 ? value - 1 : 0;

    return () => {
      onChange(newValue);
    };
  };

  return (
    <div className={cx(className, 'relative flex')}>
      <div className="flex gap-x-[2px] items-center w-[100px] p-[4px] border border-solid border-other-300 bg-white rounded-[30px] h-4">
        <button
          onClick={handleButtonClick('decrement')}
          className={cx(buttonClassNames, 'bg-other-400 disabled:bg-other-200')}
          disabled={value === null || !(value > 1)}
        >
          <IconBox className="h-[14px] w-[14px]" icon={faMinus} />
        </button>
        <input
          {...props}
          value={value ?? ''}
          type="number"
          onChange={handleChange}
          onBlur={onBlur}
          min={1}
          max={120}
          className="text-center font-brand-t3m inline w-full [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
        />
        <button
          onClick={handleButtonClick('increment')}
          className={cx(buttonClassNames, 'bg-secondary-300 disabled:bg-secondary-100')}
        >
          <IconBox className="h-[14px] w-[14px]" icon={faPlus} />
        </button>
      </div>
      {errors && (
        <Errors
          errors={errors}
          className="absolute bottom-0 translate-y-3 w-full text-center truncate"
        />
      )}
    </div>
  );
};

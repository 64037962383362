import { useMutation } from '@tanstack/react-query';
import { axios, redirectUserOnAxiosResponse } from '~/root';

type ResetPasswordPayload = {
  token: string;
  email: string;
  password: string;
  confirmPassword: string;
};

export const useResetPassword = () => {
  return useMutation({
    mutationFn: (data: ResetPasswordPayload) =>
      axios.post('/v1/users/reset-password', data).then((response) => {
        if (response?.data.redirectUrl) {
          redirectUserOnAxiosResponse(response.data.redirectUrl);
        }

        return response;
      }),
  });
};

import cx from 'classnames';
import { ReactNode, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Scroll from 'react-scroll';

import { Header } from '~/components/partials/Header';
import Footer from '~/components/ui/Footer';
import { CustomFormRoutes } from '~/pages/Order-now/constants';
import { getCanonicalHref } from '~/utils/canonicalHref';

export const InnerLayout = ({ children }: { children: ReactNode }) => {
  const location = useLocation();

  const isFooterHidden = useRef(
    Object.keys(CustomFormRoutes).some((route) => location.pathname.includes(route)),
  );

  useEffect(() => {
    Scroll.animateScroll.scrollToTop({
      duration: 0,
    });
  }, [location.pathname]);

  const canonicalHref = getCanonicalHref();

  return (
    <div
      className={cx('relative flex flex-col justify-between min-h-screen', {
        'justify-start': isFooterHidden.current,
      })}
    >
      <Helmet>
        <link rel="canonical" href={canonicalHref} />
      </Helmet>
      <Header />
      {children}
      {!isFooterHidden.current && <Footer />}
    </div>
  );
};
